import React, { useEffect, useState } from "react";

// import { AvField } from "availity-reactstrap-validation"
import CustomSelect from "../../.././Common/CustomSelect/CustomSelect";
import { Input, Label } from "reactstrap";
import { useOvermind } from "../../../../overmind";

function InputSelect2(props) {
	const {
		form,
		field,
		required,
		enabled,
		name,
		id,
		placeHolder,
		feedback = "Campo inválido",
		className = "",
		listId = "",
		isMulti = false,
		isClearable = false,
		isCreatable = false,
	} = props;

	const handleChange = async (value) => {
		// console.log(`${name}, value=${value?.id}`, value.length, typeof value)
		await form.setFieldValue(name, value);
		if (props.onChange) props.onChange({ value, form, field });
	};

	const handleBlur = (evt) => {
		if (props.onBlur) props.onBlur(evt);
	};

	const app = useOvermind("app");

	// Render for mobile
	if (app.state.isMobile) {
		return (
			<div id={``}>
				<Input
					type="hidden"
					name={name}
					value={
						form?.values && form?.values[name]
							? form?.values[name]
							: ""
					}
				/>
				<CustomSelect
					cssSkin={form.cssSkin}
					form={form}
					// isDisabled={!!props.disabled}
					name={field.name}
					// name={props.name}
					inputId={id}
					listId={field.listId}
					value={form?.values ? form?.values[name] : ""}
					options={
						typeof field.options === "function"
							? field.options({ values: form.values })
							: field.options
					}
					placeHolder={field.label}
					noEmptyOption={field.noEmptyOption}
					isMulti={field.isMulti || isMulti}
					isClearable={
						typeof field.isClearable === "boolean"
							? field.isClearable
							: isClearable
					}
					isCreatable={field.isCreatable || isCreatable}
					// onFocus={handleFocus}
					// onBlur={handleBlur}
					onChange={handleChange}
					onFocus={props.onFocus}
					disabled={!enabled}
					// required={props.required}
					// validate={{val:() => isValidNumber(value, props) }}
					// validate={props.validation}
				/>
			</div>
		);
	}

	return (
		<div id={`form-input-container-${name}`}>
			<Input
				type="hidden"
				name={name}
				value={
					form?.values && form?.values[name] ? form?.values[name] : ""
				}
			/>
			<CustomSelect
				cssSkin={form.cssSkin}
				form={form}
				// isDisabled={!!props.disabled}
				name={field.name}
				// name={props.name}
				inputId={id}
				listId={field.listId}
				value={form?.values ? form?.values[name] : ""}
				options={
					typeof field.options === "function"
						? field.options({ values: form.values })
						: field.options
				}
				placeHolder={field.label}
				noEmptyOption={field.noEmptyOption}
				isMulti={field.isMulti || isMulti}
				isClearable={
					typeof field.isClearable === "boolean"
						? field.isClearable
						: isClearable
				}
				isCreatable={field.isCreatable || isCreatable}
				// onFocus={handleFocus}
				// onBlur={handleBlur}
				onChange={handleChange}
				onFocus={props.onFocus}
				disabled={!enabled}
				// required={props.required}
				// validate={{val:() => isValidNumber(value, props) }}
				// validate={props.validation}
			/>
		</div>
	);
}

export default InputSelect2;
