
import axios from 'axios';
import {dompaApiUrl} from "../../configuration";

const dompaApi = axios.create({
	baseURL: dompaApiUrl,
	proxy: false,
	crossDomain: true,
	//withCredentials: true,
	httpsAgent: { rejectUnauthorized: true },
	timeout: 60000*10,
});

/*
const api = wrapper(http, {
  maxCacheSize: 15
}) */

/*
api.__addFilter(/users\/car-brands/)
api.__addFilter(/users\/car-models/)
api.__addFilter(/users\/car-colors/)
*/
/*

*/

export const sleep = (ms) => {
	return function(x) {
		return new Promise(resolve => setTimeout(() => resolve(x), ms));
	};
}


dompaApi.interceptors.request.use(

	async (request) => {
		request.headers.Accept = 'application/json';
		return request;
	},

	async (error) => {
		console.log('request-error', error)

		return error;
	}


);

dompaApi.interceptors.response.use(
	(response) => response,
	async (error) => {

		if (error.response) {
			// Request made and server responded
			console.error('Response Error: ', error?.response);
		} else if (error.request) {
			// The request was made but no response was received
			// console.log('Request error: ', { error, request: error.request });
			console.error('Request error: ', error);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.error('Error', error?.message);
		}

		if (error?.response?.status === 401 || error?.response?.status === 405) {
			// const authInfo = AuthInfo()
			// await Logout()
			// if (authInfo?.token) {
			// 	alert('Sessão expirada!');
				window.location = '/refreshAuth'
			// }
			return new Promise(() => {}); //Cancel chaining
			// return Promise.reject();
		} else {
			return Promise.reject(error);
		}
	}
);

export default dompaApi;
