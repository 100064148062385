import React, { useState, useMemo, useEffect, useCallback } from "react";

import "./top-menu-bar.scss";
import { Box, Drawer, List } from "@mui/material";
import { ReactComponent as Logo } from "../../../../assets/images/logo.svg";
import { ReactComponent as Headset } from "../../../../assets/images/icons/head-set.svg";
import { ReactComponent as IconMenu } from "../../../../assets/images/icons/menu.svg";
import { ReactComponent as IconMenuMobile } from "../../../../assets/images/icons/menu-mobile.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isChildRecursive } from "../../../../helpers/dom";
import { cancelEvent, cancelEventPropagation } from "../../../../helpers/utils";
import { useOvermind } from "../../../../overmind";
import MobileMenu from "./MobileMenu";
// import AvatarMenu from "../Page/AvatarMenu";

const TopMenuBar = ({ items }) => {
	const [showMenu, setShowMenu] = useState(false);
	const [showMenuMobile, setShowMenuMobile] = useState(false);
	const [topMenu, setTopMenu] = useState(null);

	const app = useOvermind("app");
	const navigate = useNavigate();
	const getSelectedTopMenuLeft = () => {
		const item = document.querySelector(
			`.top-menu-item[data-label="${topMenu.label}"]`,
		);
		if (item) {
			return item.offsetLeft - 8;
		}
		return undefined;
	};

	useEffect(() => {
		const handleHide = (e) => {
			if (!isChildRecursive("top-menu-bar", e?.target)) {
				// console.log(e?.target.id, e?.target)
				setShowMenu(false);
				cancelEvent(e);
			}
		};
		if (showMenu) {
			document.body.addEventListener("mouseover", handleHide);
			document.body.addEventListener("touchstart", handleHide);
		}
		return () => {
			document.body.removeEventListener("mouseover", handleHide);
			document.body.removeEventListener("touchstart", handleHide);
		};
	}, [showMenu]);

	const handleMouseOver = useCallback((e, menu) => {
		setShowMenu(true);
		setTopMenu(menu);
		cancelEvent(e);
	}, []);

	const handleMenuClick = useCallback((e, menu) => {
		if (menu.command) {
			setShowMenu(false);
			setTopMenu(null);
			menu.command(e);
		}
	}, []);

	if (app.state.isMobile) {
		return (
			<>
				<div id="top-menu-bar" className="px-[15px]">
					<div className="flex justify-between items-center h-[25px] mt-[25px]">
						<div className="relative z-20">
							<div
								className="text-azul py-2"
								onClick={() => navigate("/")}
							>
								<Logo width={38} height={7} />
							</div>
						</div>

						<div className="flex justify-center items-center gap-[12px]">
							<a
								className="text-preto"
								href="https://api.whatsapp.com/send?phone=5511916245978&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20da%20Dompa!"
								target={"_blank"}
								onClick={(e) => cancelEventPropagation(e)}
							>
								<Headset width={12} height={12} />
								{/*<AvatarMenu />*/}
							</a>
							{app.state.isMobile && (
								<>
									<MobileMenu
										showMenuMobile={showMenuMobile}
										setShowMenuMobile={setShowMenuMobile}
										items={items}
										handleMenuClick={handleMenuClick}
									/>
									<div
										className="text-preto"
										onClick={() => setShowMenuMobile(true)}
									>
										<IconMenuMobile width={12} height={9} />
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<div
				id="top-menu-bar"
				className="top-menu-bar debug3 d-flex flex-row justify-content-center "
				onClick={() =>
					app.state.isMobile
						? setShowMenuMobile(!showMenuMobile)
						: null
				}
			>
				<div className="top-menu-inside d-flex flex-row block-content debug2 position-relative">
					<div className="flex-grow-0 logo-container">
						<div
							className="logo-div"
							onClick={() =>
								app.state.isMobile ? null : navigate("/")
							}
						>
							<Logo width={"100%"} height={"auto"} />
						</div>
					</div>
					<div className="flex-grow-1 flex-fill menu-container d-flex flex-row">
						{!app.state.isMobile &&
							items &&
							items.map((menu, index) => {
								// if (menu?.items?.length===1)
								// 	menu = menu?.items[0]
								return (
									<div
										key={menu.label}
										onMouseOver={(e) =>
											handleMouseOver(e, menu)
										}
										onClick={(e) =>
											handleMenuClick(e, menu)
										}
										className="top-menu-item d-flex align-items-center text-18"
										data-label={menu.label}
										style={{
											fontWeight:
												showMenu &&
												topMenu?.label === menu?.label
													? 600
													: "inherit",
										}}
									>
										<div>{menu.label}</div>
									</div>
								);
							})}
					</div>
					<div className="flex-grow-0 gap-4 d-flex flex-row">
						<a
							className="menu-avatar-container"
							href="https://api.whatsapp.com/send?phone=5511916245978&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20da%20Dompa!"
							target={"_blank"}
							onClick={(e) => cancelEventPropagation(e)}
						>
							<Headset width={20} height={20} />
							{/*<AvatarMenu />*/}
						</a>
						{app.state.isMobile && (
							<>
								<MobileMenu
									showMenuMobile={showMenuMobile}
									setShowMenuMobile={setShowMenuMobile}
									items={items}
									handleMenuClick={handleMenuClick}
								/>
								<div
									className="flex-grow-0 menu-mobile-container"
									onClick={() => setShowMenuMobile(true)}
								>
									<IconMenu width={22} height={22} />
								</div>
							</>
						)}
					</div>

					{topMenu?.items?.length > 0 && (
						<div
							className={`top-menu-container d-flex flex-row  ${
								showMenu ? "open" : "closed"
							}`}
							style={{ paddingLeft: getSelectedTopMenuLeft() }}
							// onMouseOver={e => handleMouseOver(e, topMenu)}
						>
							{topMenu?.items?.length > 0 &&
								topMenu.items.map((menu) => {
									// if (!menu.items?.length)
									// 	return null

									return (
										<div className="d-flex flex-column gap-2 debug3 sub-menu-container  ">
											{menu?.label && (
												<div
													className="sub-menu-title text-18"
													onClick={(e) =>
														handleMenuClick(e, menu)
													}
												>
													{menu?.label || "empty"}
												</div>
											)}
											<div className="sub-menu-items text-18 ">
												{menu.items?.length &&
													menu.items.map(
														(subMenu) => {
															return (
																<div
																	className="sub-menu-item-final text-18"
																	onClick={(
																		e,
																	) =>
																		handleMenuClick(
																			e,
																			subMenu,
																		)
																	}
																>
																	{subMenu.label ||
																		"empty"}
																</div>
															);
														},
													)}
											</div>
										</div>
									);
								})}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default TopMenuBar;
