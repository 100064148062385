import React, { useEffect, useState } from "react";

// import {FormFeedback, FormGroup, Input} from "reactstrap";
// import {Input} from "reactstrap";
import InputMask from "react-input-mask";
import { onlyNumbers } from "../../../../helpers/utils";
import {
	mask8Digits,
	mask9Digits,
	maskUS,
} from "../../../../helpers/constants/staticLists";
import { Label } from "reactstrap";
import { useOvermind } from "../../../../overmind";
import "./text.css";
import Phone from "../../../../assets/images/static/talk-to-us/Phone.svg";

const InputPhone = ({
	form,
	field,
	required,
	enabled,
	name,
	id,
	placeHolder,
	feedback = "Campo inválido",
	className = "w-100",
	...props
}) => {
	const [mask, setMask] = useState("(99) 99999-9999");
	const value = form?.values ? form?.values[name] : null;

	const handleKeyDown = (e) => {
		const code = e.keyCode || e.which || e.key || e.code;

		if (code === 32 || code?.toString()?.toLowerCase() === "space") {
			e.preventDefault();
		}
	};

	useEffect(() => {
		if (field.countryCodeField) updateMask(value);
	}, [form.values[field.countryCodeField]]);

	const updateMask = (value) => {
		let newMask;
		if (field.countryCodeField) {
			if (form.values[field.countryCodeField] === "55") {
				newMask =
					value?.length >= 6 &&
					(value?.[5] === "9" ||
						value?.[5] === "_" ||
						value?.[5] === " ")
						? mask9Digits
						: mask8Digits;
			} else if (form.values[field.countryCodeField]) {
				newMask = maskUS;
			}
		} else {
			newMask =
				value?.length >= 6 &&
				(value?.[5] === "9" || value?.[5] === "_" || value?.[5] === " ")
					? mask9Digits
					: mask8Digits;
		}
		setMask(newMask);
	};

	const handleChange = async (e) => {
		const value = e.target.value;
		updateMask(value);
		await form.setFieldValue(name, onlyNumbers(value));

		if (props.onChange) {
			props.onChange({ value, form });
		}
	};

	const app = useOvermind("app");

	// Render for mobile
	if (app.state.isMobile) {
		return (
			<div className="relative w-[42.133vw] leading-none">
				<Label for={id}>
					<p className="text-azul font-roboto text-[3.2vw] pt-[2.667vw]">
						{field.label}
					</p>
				</Label>
				<div className="relative ">
					<img
						src={Phone}
						alt="icon"
						className="absolute left-3 top-1/2 transform -translate-y-1/2 w-[3.2vw] h-[2.4vw]"
					/>

					<InputMask
						className="pl-[8vw] bg-transparent text-azul font-roboto w-[42.133vw] shadow-xl h-[5.867vw] form-control-custom rounded"
						mask={mask}
						maskChar=" "
						placeholder={field.label}
						name={name}
						id={id}
						type="text"
						autoComplete="disabled"
						onChange={handleChange}
						onKeyDown={handleKeyDown}
						onFocus={props.onFocus}
						inputMode="tel"
						value={value}
						disabled={!enabled}
					/>
				</div>
			</div>
		);
	}

	return (
		<>
			<InputMask
				className={className}
				mask={mask}
				maskChar=" "
				placeholder={placeHolder}
				name={name}
				id={id}
				type="text"
				autoComplete="disabled"
				onChange={handleChange}
				onKeyDown={handleKeyDown}
				onFocus={props.onFocus}
				inputMode="tel"
				value={value}
				disabled={!enabled}
			/>
			<Label for={id}>{field.label}</Label>
		</>
	);
};

export default InputPhone;
