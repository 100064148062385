import React, { useState, useMemo, useEffect, useCallback } from "react";

import { ReactComponent as CirclesImage1 } from "../../../../assets/images/circles.svg";
import { ReactComponent as CirclesImage2 } from "../../../../assets/images/circles-2.svg";
import "./our-mission-vision.scss";
import Circles from "../Circles";
import { useOvermind } from "../../../../overmind";

// import {ReactComponent as IconNewWindow} from "../../../../assets/images/icons/new-window.svg"

const OurMissionVision = ({ actions }) => {
	const app = useOvermind("app");

	if (app.state.isMobile) {
		return (
			<div className="py-[13.333vw] text-branco px-[15px] space-y-[8.533vw] leading-none relative">
				<CirclesImage2 className="w-[34.667vw] h-[37.067vw] rotate-180 absolute left-0 top-[5vw] scale-y-[-1]" />
				<CirclesImage2 className="w-[34.667vw] h-[37.067vw] rotate-180 absolute right-0 bottom-[0vw] scale-x-[-1]" />
				<div className="bg-azul p-[8.533vw] rounded-2xl relative z-10">
					<div className="font-titillium text-[8.533vw] font-semibold uppercase">
						Nossa missão
					</div>
					<div className="font-roboto text-[4vw] pt-[6.667vw]">
						Abrir as portas das campanhas promocionais para todo
						mundo. Criar soluções sob medida e transformar dados em
						ideias que fazem a diferença.
					</div>
				</div>

				<div className="bg-roxo-3 p-[8.533vw] rounded-2xl text-end relative z-10">
					<div className="font-titillium text-[8.533vw] font-semibold uppercase">
						Nossa visão
					</div>
					<div className="font-roboto text-[4vw] pt-[6.667vw]">
						Revolucionar o mundo das campanhas promocionais com
						soluções inovadoras, acessíveis e completas, abrindo
						novas portas para negócios com dados que realmente fazem
						acontecer.
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="our-mission-vision debug1 d-flex flex-row justify-content-center position-relative">
			<div className="d-flex flex-row block-content ">
				{/*<div className="position-absolute boxed bg-gradient-1 z-2"/>*/}
				{/*<div className="position-absolute boxed bg-gradient-2 z-2"/>*/}

				<div className="card-container d-flex flex-column content z-2 debug w-100">
					<div className="card-box-mission flex-grow-0 debug2 d-flex flex-row align-items-center">
						<div className="title flex-grow-0 debug3 font-tt">
							Nossa
							<br />
							missão
						</div>
						<div className="description flex-grow-1 flex-fill debug3">
							Abrir as portas das campanhas promocionais para todo
							mundo.
							<br />
							Criar soluções sob medida e transformar dados em
							ideias que fazem a diferença.
						</div>
					</div>

					<div className="card-box-vision flex-grow-0 debug2 d-flex flex-row align-items-center ">
						<div className="description flex-grow-1 flex-fill debug3">
							Revolucionar o mundo das campanhas promocionais com
							soluções inovadoras, acessíveis e completas, abrindo
							novas portas para negócios com dados que realmente
							fazem acontecer.
						</div>
						<div className="title flex-grow-0 debug3 font-tt">
							Nossa
							<br />
							visão
						</div>
					</div>
				</div>

				<div className="position-absolute boxed w-100 z-0 debug4 overflow-hidden">
					{/*<Circles*/}
					{/*	Image={CirclesImage2}*/}
					{/*	rotateX={0}*/}
					{/*	rotateY={0}*/}
					{/*	rotate={-45}*/}
					{/*	width={580}*/}
					{/*	height={480}*/}
					{/*	top={50}*/}
					{/*	right={-380/2}*/}
					{/*/>*/}
					<Circles
						Image={CirclesImage2}
						rotateX={0}
						rotateY={0}
						rotate={-45 * 2 - 24}
						width={840}
						height={540}
						bottom={-20}
						left={-200}
					/>
				</div>
			</div>
		</div>
	);
};

export default OurMissionVision;
