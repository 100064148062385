import React, { useState, useMemo, useEffect, useCallback } from "react";

import IMG from "../../../assets/images/static/clients/byd.png";

export const dataClientsBYD = {
	id: "byd",
	name: `BYD`,
	challenge: `Lançamento em 7 dias uma campanha nacional para a BYD, integrando 87 concessionárias, validação de compras em tempo real para sorteio de uma experiência na UEFA Euro.`,
	image: IMG,
	solution: `Confira os resultados das nossas campanhas e veja como transformamos ideias em ações que geram engajamento e impacto real.`,
	solutionItems: [
		{
			description: `Aprovação pela SPA em 48 horas`,
		},
		{
			description: `Criação de uma landing page interativa`,
		},
		{
			description: `Construção de um dashboard inteligente`,
		},
		{
			description: `Validando dados com IA`,
		},
	],
	results: `Campanha lançada em prazo com integração eficiente das concessionárias com validação automatizada segura e confiável e uma experiência exclusiva entregue com sucesso, fortalecendo o impacto da marca.`,
	website: `http://www.byd.com/`,
};
