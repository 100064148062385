import {
	deviceDetect as deviceDetectFunc,
	isIOS,
	isSafari,
	isMobile,
	isDesktop,
	isTablet,
	isMacOs,
	isSmartTV,
	isConsole,
	isWearable,
	isMobileSafari,
	isChromium,
	isMobileOnly,
	isAndroid,
	isWinPhone,
	deviceType,
	osName,
	osVersion,
	browserVersion,
	fullBrowserVersion,
	browserName,
	isWindows,
	mobileModel,
	getUA,
} from "react-device-detect";

/* :::::::::::::::::: ENVIRONMENT ::::::::::::::::::*/
//export const environment = "production"
//export const environment = "localhost"
//export const environment = "homolog"

export const environment = process.env.NODE_ENVIROMENT || "localhost";
export const isLocalhost = environment === "localhost" || environment === "lan";
export const autoFillForms = false;
export const limitFreeTestsDayly = 3;

if (0 && environment === "development") {
	window.onerror = function (msg, src, lineno, colno, error) {
		alert(`
			\nmsg: ${msg}		
			\nsrc: ${src}		
			\nlineno: ${lineno}		
			\ncolno: ${colno}		
			\nerror: ${error}		
			\nmessage: ${error?.message}		
		`);
	};

	window.onunhandledrejection = (e) => {
		alert(`Error Message: ${e?.reason?.message}`);
	};
}

/*:::::::::::::::::: REACT NATIVE APP INJECTIONS/DETECTIONS ::::::::::::::::::*/

//React Native Interface
export const JSInterface = window.MyJSInterface;

//Is Running from NATIVE APP?
export const isRNWebView = window.isRNWebView || false;

//Biometry type, if available [FaceID|TouchID]
export const RNBiometryType = window.RNBiometryType;

//Is Running from Installed PWA ?
export const isInStandaloneMode =
	window.matchMedia("(display-mode: standalone)").matches ||
	window.navigator.standalone ||
	document.referrer.includes("android-app://");

//Is WebAuthN API available in web navigator?
export const WebAuthN = { available: false };

if (window.PublicKeyCredential) {
	window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
		.then((available) => (WebAuthN.available = available))
		.catch(() => {});
}

/*:::::::::::::::::: DEVICE INFO DETECTION ::::::::::::::::::*/

const deviceDetect = deviceDetectFunc();

//Helper variables
export const Device = {
	isRNWebView,
	RNBiometryType,

	isIOS,
	isSafari,
	isMobile,
	isInStandaloneMode,
	isDesktop,
	isTablet,
	isWindows,
	isMacOs,
	WebAuthN,
	deviceType: isTablet ? "tablet" : deviceType,
	osName,
	osVersion,
	browserVersion,
	fullBrowserVersion,
	browserName,
	mobileModel,
	deviceDetect,
};

//Variables sent to api in some requests (/auth/login, ...)
export const reportDeviceInfo = {
	browserVersion: fullBrowserVersion || "",
	deviceName: browserName || mobileModel || "",
	deviceType: Device.deviceType || "",
	mobileModel: (mobileModel === "none" ? "" : mobileModel) || "",
	osName: osName || "",
	osVersion: osVersion || "",
	pagePlatform: isInStandaloneMode ? "pwa" : isRNWebView ? "native" : "web",
	userAgent: getUA || "",
	// deviceDetect
};

export const appUrl =
	environment === "localhost"
		? isRNWebView
			? process.env.REACT_APP_URL_MOBILE
			: process.env.REACT_APP_URL
		: process.env.REACT_APP_URL;

export const dompaApiUrl = environment === 'localhost'
	? isRNWebView ? process.env.REACT_APP_DOMPA_API_URL : process.env.REACT_APP_DOMPA_API_URL
	: process.env.REACT_APP_DOMPA_API_URL

// Misc configs
export const defaultPageTitle =
	environment === "production" ? "Dompa" : `[${environment}] Dompa`;

export const acceptUploadTypeImage =
	"image/png, image/jpeg, image/jpg, image/bmp";
export const acceptUploadTypeImageAndPdf =
	"image/png, image/jpeg, image/jpg, image/bmp, application/pdf";
export const defaultAcceptFiles = acceptUploadTypeImageAndPdf;
// export const defaultTestsPassword = isLocalhost
// 	? process.env.REACT_APP_TESTS_PASSWORD || ""
// 	: "";

console.log("environment", {
	environment,
	appUrl,
	userAgent: navigator.userAgent,
	reportDeviceInfo,
	Device,
});
