import React, {useState, useMemo, useEffect, useCallback} from "react"

import "./service-applications.scss"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import nl2br from "react-nl2br";
import {ReactComponent as IconScreen} from "../../../../assets/images/icons/screen.svg"

import { Swiper, SwiperSlide } from "swiper/react";
import {
  EffectCoverflow,
  FreeMode,
  Navigation,
  Pagination,
  Thumbs,
} from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Card = (
	{
		Icon,
		title,
		description,
		command,
		image,
		descriptionPaddingRight = "0rem",
		actions,
	}
) => {
	return (
		<div
			className="service-applications-card d-flex flex-column position-relative debug5"

		>
			<div className="card-icon flex-grow-0 debug1">
				<IconScreen width={44} height={45} />
			</div>

			<div className="card-title text-28 flex-grow-0 debug2">{nl2br(title)}</div>
			<div className="card-description text-18 flex-grow-0 debug2" style={{paddingRight:descriptionPaddingRight}} >{nl2br(description)}</div>
		</div>
	)
}

const SwipeDesktop = (props) => {
	return (
		<Swiper
			className={props.className}
			slidesPerView={'auto'}
			// grabCursor={true}
			// spaceBetween={0}


			centeredSlides={false}
			pagination={{
				clickable: true,
			}}
			spaceBetween={24}
			initialSlide={0}
			// slidesPerView={4}
			// freeMode={true}
			// watchSlidesProgress={true}
			modules={[Navigation, Thumbs]}
			{...props}
		>
			{props.children}
		</Swiper>
	);
};

const ServicesApplicationSwiper = (
	{
		className,
		cards,
		...props
	}
) => {

	const navigate = useNavigate()

	return (
		<SwipeDesktop className={className}>
			{cards.map((card, index) => {
				return (
					<SwiperSlide key={card.index}>
						<Card
							// onCartChanged={onCartChanged}
							// color={"#FACF03"}
							// colorTag={"#356B67"}
							{...card}
						/>
					</SwiperSlide>
				);
			})}
		</SwipeDesktop>
	)
}




export default ServicesApplicationSwiper;
