import React, { useEffect, useRef } from "react";
import "./tab-texts.scss";
import { useWindowSize } from "../../../../helpers/uses/windowsize";
import { useOvermind } from "../../../../overmind";

const TabTexts = ({ tabs, activeTab, onTabChange, className, ...props }) => {
	const itemsRef = useRef();
	const markerRef = useRef();
	// const markerContainerRef = useRef()

	const windowSize = useWindowSize();

	useEffect(() => {
		if (!activeTab || !windowSize.width || !windowSize.height) return;
		try {
			const markerElement = markerRef.current;
			// const markerContainerElement = markerContainerRef.current
			const itemsElement = itemsRef.current;
			const itemElement = itemsElement.querySelector(
				`[data-id="${activeTab?.id}"]`,
			);

			// console.log(markerElement)
			// const rect = itemElement.getBoundingClientRect()
			// const rectContainer = markerContainerElement.getBoundingClientRect()

			markerElement.style.left = itemElement.offsetLeft + "px";
			markerElement.style.width = itemElement.offsetWidth + "px";
			// markerElement.style.top = "0px";
		} catch (e) {}
	}, [activeTab.id, windowSize.width, windowSize.height]);

	const app = useOvermind("app");

	// Render for mobile
	if (app.state.isMobile) {
		return (
			<div ref={itemsRef} className={`w-full px-[15px] ${className}`}>
				<div
					className="flex justify-content-around gap-[6.133vw] px-[8vw]"
					// style={{ height: "2rem" }}
				>
					{tabs &&
						tabs.map((tab, index) => {
							return (
								<div
									key={tab.id}
									onClick={() => onTabChange(tab, index)}
									className={`font-roboto text-[3.2vw] text-azul ${
										activeTab.id === tab.id
											? "font-semibold border-b-[1px] border-b-azul-3"
											: "font-normal"
									}`}
									data-state={
										activeTab.id === tab.id
											? "active"
											: "inactive"
									}
									data-id={tab.id}
								>
									{tab.label}
								</div>
							);
						})}
				</div>
				<div className="w-full h-[2px] bg-azul" />
			</div>
		);
	}

	return (
		<div ref={itemsRef} className={`tab-texts ${className}`}>
			<div
				className="debug4 d-flex justify-content-around mb-1 pt-1 position-relative"
				// style={{ height: "2rem" }}
			>
				{tabs &&
					tabs.map((tab, index) => {
						return (
							<div
								key={tab.id}
								onClick={() => onTabChange(tab, index)}
								className="item flex-fill text-center debug3 text-nowrap"
								data-state={
									activeTab.id === tab.id
										? "active"
										: "inactive"
								}
								data-id={tab.id}
							>
								{tab.label}
							</div>
						);
					})}
			</div>
			<div className="position-relative debug6 w-100 ">
				<div
					ref={markerRef}
					className="position-absolute marker marker-current debug6"
				/>
				<div className="position-absolute marker marker-container debug6 w-100 " />
			</div>
		</div>
	);
};

export default TabTexts;
