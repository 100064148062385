const _ = require('lodash')

//import states directly

export const defaultApp = {
	isMobile : false,
}

export const app = _.cloneDeep(defaultApp)

