import React, {useState, useMemo, useEffect, useCallback} from "react"
import TopMenuBar from "../TopMenuBar/TopMenuBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import services from "../../../../services";

// import {ReactComponent as Logo} from "../../assets/images/logo.svg"
// // import AvatarMenu from "../Page/AvatarMenu";
// import {isChildRecursive} from "../../helpers/dom";
// import {cancelEvent} from "../../helpers/utils";

const Header = (
	{
		items,
	}
) => {

	const navigate = useNavigate()

	const menu = useMemo(() => {
		const ourServices = services.services.list()
		const ourProducts = services.products.list()
		return [
			{
				label : "Quem Somos",
				command : () => navigate(`/quemsomos`),
			},
			{
				label : "Serviços",
				command : e => navigate(`/#servicos`),
				items : [
					{
						// label : "Quem Somos",
						command : () => {},
						items : ourServices.map(s => {
							return {
								label : s.mainTitle,
								command : e => navigate(`/servicos/${s.id}`)
							}
						}),
					}
				],
			},
			// {
			// 	label : "Blog",
			// 	command : () => {},
			// },
			{
				label : "Mídia",
				command : e => navigate(`/#midia`)
			},
			{
				label : "Nossos Cases",
				command : e => navigate(`/#cases`)
			},
			{
				label : "Produtos",
				command : e => navigate(`/#produtos`),
				items : [
					{
						// label : "Quem Somos",
						command : () => {},
						items : ourProducts.map(s => {
							return {
								label : s.name,
								command : e => navigate(`/produtos/${s.id}`)
							}
						}),
					}
				],
			},
			{
				label : "Contato",
				command : e => navigate(`/#contato`)
			},
		]
	}, [])

	return <>
		<TopMenuBar items={menu}/>
	</>
}




export default Header;
