import React, { useState, useMemo, useEffect, useCallback } from "react";

import "./cases.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as IconNewWindow } from "../../../../assets/images/icons/new-window.svg";
// import {ReactComponent as Circle} from "../../../../assets/images/circles-3.svg"
// import WhoWeAreImage from "../../../../assets/images/static/who-we-are.jpg"
import services from "../../../../services";

import nl2br from "react-nl2br";
import Button from "../../layout/Button/Button";
import { useOvermind } from "../../../../overmind";

const Cases = ({ ...props }) => {
	const [caseId, setCaseId] = useState("bello-futvolei");

	const cases = useMemo(() => services.cases.list(), []);
	const selectedCase = useMemo(
		() => cases.find((c) => c.id === caseId),
		[caseId, cases],
	);

	const navigate = useNavigate();

	const app = useOvermind("app");

	// Render for mobile
	if (app.state.isMobile) {
		return (
			<div
				className="mt-[10.667vw] mx-[4vw] bg-preto text-branco py-[8.533vw] px-[6.4vw] rounded-2xl"
				id="cases"
			>
				<div className="">
					<div className="font-titillium text-[8.533vw] font-bold leading-none pb-[8.533vw]">
						Cases
					</div>
					<div className="mb-[6.4vw] pb-[3.2vw] flex gap-[6.4vw] overflow-x-scroll text-nowrap w-full no-scrollbar border-b-branco border-b-[.1px]">
						{cases?.length &&
							cases.map((item) => (
								<div
									key={item.name}
									onClick={() => setCaseId(item.id)}
									className={`text-[2.667vw] font-roboto ${
										caseId === item.id
											? "text-azul-3 font-semibold"
											: "inactive"
									}`}
								>
									{item.name}
								</div>
							))}
					</div>

					<div className="">
						<div className="leading-none">
							<div className="font-roboto text-[2.133vw] pb-[3.2vw]">
								Conheça o Case
							</div>
							<div className="font-titillium text-[6.4vw] font-semibold max-w-[70%] pb-[6.4vw]">
								{selectedCase.title}
							</div>
							<div className="font-roboto text-[3.2vw] leading-tight">
								{nl2br(selectedCase.description)}
							</div>

							<div className="relative z-10 place-self-end pt-[3.467vw] pb-[1vw] mb-[3.467vw] border-b-2 border-branco">
								<Button
									template={"link-underline"}
									size={"medium"}
									label={"Saiba Mais"}
									onClick={() =>
										navigate(`/clientes/${selectedCase.id}`)
									}
									IconLeft={IconNewWindow}
									iconHeight={14}
									iconWidth={16}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div
			className="d-flex flex-row d-flex justify-content-center position-relative mt-5"
			id="cases"
		>
			<div className="cases d-flex flex-column block-content">
				<div className="main-title">Cases</div>
				<div className="menu d-flex flex-row justify-content-center text-18 py-4 mb-justify-unset">
					{cases?.length &&
						cases.map((item) => (
							<div
								key={item.name}
								onClick={() => setCaseId(item.id)}
								className={`item ${
									caseId === item.id ? "active" : "inactive"
								}`}
							>
								{item.name}
							</div>
						))}
				</div>
				<div className="d-flex flex-row debug1 h-full max-h-[1133px]">
					<div className="min-w-[40%] relative">
						<div
							className={`absolute inset-0 mb-hide bg-cover ${
								`bg-` + selectedCase.position
							} `}
							style={{
								backgroundImage: `url(${selectedCase.image})`,
							}}
						/>
					</div>
					<div className="middle-bar flex-grow-0"></div>
					<div className="info-container d-flex flex-column debug3">
						<div className="title debug2 text-18 fw-medium">
							Conheça o Case
						</div>
						<div className="title debug2 text-60">
							{selectedCase.title}
						</div>
						<div className="description debug2 text-28 ">
							{nl2br(selectedCase.description)}
						</div>
						<div className="actions debug2 d-flex flex-grow-1 flex-fill justify-content-end align-items-end">
							<div className="debug2 fw-medium justify-content-end ">
								<Button
									template={"link-underline"}
									size={"medium"}
									label={"Saiba Mais"}
									onClick={() =>
										navigate(`/clientes/${selectedCase.id}`)
									}
									IconLeft={IconNewWindow}
									iconHeight={14}
									iconWidth={16}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Cases;
