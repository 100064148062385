import React, {useState, useMemo, useEffect, useCallback} from "react"

import "./top-menu-bar.scss"
import { Box, Drawer, List } from "@mui/material";
import {ReactComponent as Logo} from "../../../../assets/images/logo.svg"

const MobileMenu = (
	{
		showMenuMobile,
		setShowMenuMobile,
		items,
		handleMenuClick,
	}
) => {

	return <>
		<Drawer className="mobile-menu" anchor={"left"} open={showMenuMobile} onClose={() => setShowMenuMobile(false)}>
			<div className="d-flex flex-column justify-content-center align-items-center debug3 ">
				<div className="flex-grow-0 logo-container">
					<div className="logo-div debug-1" onClick={() => navigate('/')}>
						<Logo width={"100%"} height={"auto"} />
					</div>
				</div>
				<div className="flex-grow-1 flex-fill menu-container d-flex flex-column debug2">
					{items && items.map((menu, index) => {
						// if (menu?.items?.length===1)
						// 	menu = menu?.items[0]
						return <div
							key={menu.label}
							onClick={e => {
								setShowMenuMobile(false)
								handleMenuClick(e, menu)
							}}
							className="top-menu-item d-flex align-items-center"
							data-label={menu.label}
						>
							<div>{menu.label}</div>
						</div>
					})}
				</div>
			</div>
		</Drawer>
	</>
}




export default MobileMenu;
