import React, { useEffect, useState } from "react";
import { Input, Label } from "reactstrap";
import "./text.css";
import Chat from "../../../../assets/images/static/talk-to-us/Chat.svg";
import { useOvermind } from "../../../../overmind";

const InputTextArea = ({
	field,
	form,
	required,
	name,
	id,
	placeHolder,
	feedback = "Campo inválido",
	className = "",
	...props
}) => {
	const handleChange = async (e) => {
		const value = e.target.value;
		await form.setFieldValue(name, value);

		if (props.onChange) {
			props.onChange({ value, form, field });
		}
	};

	const app = useOvermind("app");

	// Render for mobile
	if (app.state.isMobile) {
		return (
			<div className="relative w-full ">
				<Label for={id}>
					<p className="text-azul font-roboto text-[3.2vw] pt-[2.667vw]">
						{field.label}
					</p>
				</Label>
				<div className="relative">
					<img
						src={Chat}
						alt="icon"
						className="absolute left-0 top-0 ml-[10px] mt-[10px] w-[3.2vw]"
					/>
					<Input
						type="textarea"
						className="pl-[8vw] bg-transparent text-azul font-roboto w-full shadow-xl h-[26.4vw] form-control-custom"
						placeholder={"Opcional"}
						name={name}
						id={id}
						value={form?.values ? form?.values[name] : null}
						onChange={handleChange}
						onBlur={form.handleBlur}
						onFocus={props.onFocus}
						style={{
							height: `${(field?.rows || 5) + 3.75}rem`,
						}}
					/>
				</div>
			</div>
		);
	}

	return (
		<>
			<Input
				type="textarea"
				className={className}
				placeholder={placeHolder}
				name={name}
				id={id}
				value={form?.values ? form?.values[name] : null}
				onChange={handleChange}
				onBlur={form.handleBlur}
				onFocus={props.onFocus}
				style={{ height: `${(field?.rows || 5) + 3.75}rem` }}
			/>
			<Label for={id}>{field.label}</Label>
		</>
	);
};

export default InputTextArea;
