import React, { useState, useMemo, useEffect, useCallback } from "react";

import "./products-more-info.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as IconNewWindow } from "../../../../assets/images/icons/new-window.svg";
import { ReactComponent as Circle } from "../../../../assets/images/circles-3.svg";
import { ReactComponent as Circle2 } from "../../../../assets/images/circles-2.svg";
// import WhoWeAreImage from "../../../../assets/images/static/who-we-are.jpg"
import { ReactComponent as IconScreen } from "../../../../assets/images/icons/screen.svg";
import nl2br from "react-nl2br";
import { ReactComponent as CirclesImage } from "../../../../assets/images/circles.svg";
import Circles from "../../../components/blocks/Circles";
import ResultsImage from "../../../../assets/images/backgrounds/resultados.jpg";
import services from "../../../../services";
import Button from "../../../components/layout/Button/Button";
import { useOvermind } from "../../../../overmind";

const ProductsMoreInfo = ({ data, ...props }) => {
	const navigate = useNavigate();

	const app = useOvermind("app");

	if (app.state.isMobile) {
		return (
			<div className="mt-[13.333vw] pb-[6vw] text-preto ">
				<div className="">
					<div className="relative flex justify-center items-center">
						<Circle2 className="max-w-[20vw] h-[40vw] scale-x-[-1]" />
						<div className="flex flex-col justify-end items-end w-full pr-[15px]">
							<div className="text-[8.533vw] font-semibold text-end leading-none font-titillium pb-[4.267vw]">
								{nl2br(data.knowMoreTitle2)}
							</div>
							<div>
								<div className="text-[3.2vw] font-roboto text-end">
									{nl2br(data.knowMoreDescription)}
								</div>
							</div>
						</div>
					</div>

					<div className="flex justify-between items-center pt-[15vw] px-[15px]">
						<div className="min-w-[44vw] max-w-[44vw] min-h-[54.933vw] max-h-[54.933vw]  relative">
							<img
								className="absolute inset-0 object-cover object-top w-full h-full rounded-xl z-10"
								alt="product"
								src={data.knowMoreImage2}
							/>
						</div>
						<div className="space-x-[6.133vw]">
							<div className="text-[3.2vw] font-roboto font-semibold text-end max-w-[90%] ml-auto">
								{data.knowMoreInfoDescription}
							</div>
							<div className="pt-[4.8vw]">
								<Button
									template="light-blue"
									href={data.website}
									target={"_blank"}
									size={"medium"}
									label={"Acesse o site"}
									iconHeight={14}
									iconWidth={16}
									className="bg-azul-3 font-normal text-branco text-[3.2vw] rounded-md w-[42.133vw] h-[10.4vw] ml-auto shadow-md"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="d-flex flex-row d-flex flex-row justify-content-center position-relative z-5">
			<div className="products-more-info d-flex flex-column debug1 block-content ">
				<div className="description-container d-flex mb-row-column-reverse mb-align-items-center">
					<div className="flex-grow-1 flex-fill d-flex flex-column debug3 ">
						<div className="title debug2 text-128 font-tt">
							{nl2br(data.knowMoreTitle2)}
						</div>
						<div className="description debug2 text-24 ">
							{nl2br(data.knowMoreDescription)}
						</div>
					</div>

					<div className="image-container flex-grow-0 d-flex flex-column debug4 debug3 position-relative">
						<div
							className="image boxed position-absolute z-2 debug3"
							style={{
								backgroundImage: `url(${data.knowMoreImage2})`,
							}}
						/>
						{/*<div className="position-absolute boxed sub-gradient z-1"/>*/}
					</div>
				</div>

				<div className="results-container d-flex flex-column ">
					<div className="results-description  debug3">
						{data.knowMoreInfoDescription}
					</div>
					<div className="results-actions  debug3 d-flex justify-content-center align-items-center">
						<Button
							template="light-blue"
							href={data.website}
							target={"_blank"}
							size={"medium"}
							label={"Acesse o site"}
							// IconLeft={IconNewWindow}
							iconHeight={14}
							iconWidth={16}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductsMoreInfo;
