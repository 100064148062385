import React, { useState, useMemo, useEffect, useCallback } from "react";

import "./talk-to-us.scss";
// import {ReactComponent as IconDompa} from "../../../../assets/images/icons/influencers.svg"
import IconDompa from "../../../../assets/images/icons/dompa-icon.png";
import Button from "../../layout/Button/Button";
import TabTexts from "../../layout/TabTexts/TabTexts";
import CustomForm, { useCustomForm } from "../../../../components/CustomForm";
import FormFields from "../../../../components/CustomForm/FormFields";
import { optionsCampaignMode } from "../../../../helpers/constants/staticLists";
import services from "../../../../services";
import { useOvermind } from "../../../../overmind";
// import {ReactComponent as IconNewWindow} from "../../../../assets/images/icons/new-window.svg";
// import services from "../../../../services";

const TalkToUs = ({ ...props }) => {
	const [tabIndex, setTabIndex] = useState(0);
	const [activeTab, setActiveTab] = useState(0);
	const [messageSent, setMessageSent] = useState(false);
	const [messageSending, setMessageSending] = useState(false);

	const tabs = useMemo(
		() => [
			{
				id: "info",
				label: "Campos gerais",
			},
			{
				id: "additional",
				label: "Seleção do serviço",
			},
			{
				id: "service",
				label: "Adicionais",
			},
		],
		[],
	);

	useEffect(() => {
		setActiveTab(tabs.find((a, index) => index === tabIndex));
	}, [tabIndex, tabs]);

	const handleClick = useCallback((tab, index) => {
		setTabIndex(index);
	}, []);

	const handleNext = useCallback(
		(values) => {
			console.log({ values });
			if (tabIndex === tabs.length - 1) {
				setMessageSending(true);
				services.app
					.talkToUs(values)
					.then((data) => {
						setMessageSent(true);
						// alert('Mensagem enviada')
					})
					.catch((e) => {
						alert(`Ocorreu um erro ao enviar`);
					})
					.finally(() => setMessageSending(false));
			} else {
				setTabIndex(tabIndex + 1);
			}
		},
		[tabs, tabIndex],
	);

	const fields = useMemo(() => {
		const allServices = services.services.list();

		return [
			{
				name: "name",
				type: "text",
				required: true,
				label: "Nome Completo",
				grid: { md: 6 },
				category: "info",
			},
			{
				name: "document",
				type: "cpfcnpj",
				required: true,
				label: "CNPJ ou CPF",
				grid: { md: 6 },
				category: "info",
			},
			{
				name: "email",
				type: "email",
				required: true,
				label: "E-mail",
				grid: { md: 6 },
				category: "info",
			},
			{
				name: "phone",
				type: "phone",
				required: true,
				label: "Telefone",
				grid: { md: 6 },
				category: "info",
			},
			{
				name: "companyName",
				type: "text",
				required: true,
				label: "Nome da Empresa",
				grid: { md: 12 },
				category: "info",
			},
			{
				name: "message",
				type: "textarea",
				rows: 3,
				label: <>Mensagem Geral</>,
				grid: { md: 12 },
				category: "info",
			},
			{
				name: "segment",
				type: "text",
				label: "Segmentação Desejada",
				grid: { md: 12 },
				category: "service",
			},
			{
				name: "objective",
				type: "text",
				label: "Objetivo Principal",
				grid: { md: 12 },
				category: "service",
			},
			{
				name: "usedTools",
				type: "text",
				label: "Ferramentas Usadas Atualmente",
				grid: { md: 12 },
				category: "service",
			},
			{
				name: "desiredService",
				type: "select2",
				isMulti: true,
				isClearable: true,
				options: allServices.map((p) => {
					return {
						id: p.mainTitle,
						caption: p.mainTitle,
					};
				}),
				label: "Serviço Desejado",
				grid: { md: 12 },
				category: "additional",
			},
		];
	}, []);

	const form = useCustomForm({
		fields,
		cssSkin: "form-admin",
		initialValues: {
			desiredService: [],
		},
		// onFormChange,
		// readOnly : skipSave,
		onSubmit: handleNext,
	});

	const app = useOvermind("app");

	// Render for mobile
	if (app.state.isMobile) {
		return (
			<CustomForm form={form}>
				<div className="pt-[10.667vw]" id="contato">
					<div className=" flex flex-col justify-center items-center">
						<img src={IconDompa} className="w-[8vw]" alt="logo" />

						<div className="pt-[3.2vw] pb-[5.333vw] font-titillium font-bold text-[6.4vw] text-azul">
							Fala Conosco
						</div>

						{messageSent ? (
							<div className="py-5 my-5 d-flex justify-content-center align-items-center">
								<div
									className="font-tt text-16"
									style={{ color: "var(--blue-4)" }}
								>
									Mensagem enviada !
								</div>
							</div>
						) : (
							<>
								<TabTexts
									tabs={tabs}
									className=""
									activeTab={activeTab}
									onTabChange={(tab, index) =>
										handleClick(tab, index)
									}
								/>

								<div
									className="pt-[6.4vw] w-full px-[15px]"
									data-state="active"
								>
									<div className="">
										<FormFields
											form={form}
											fields={fields.filter(
												(field) =>
													field?.category ===
													activeTab?.id,
											)}
										/>
									</div>
								</div>

								<div className="w-full flex justify-end pr-[14px]">
									<Button
										template="blue"
										type={"submit"}
										size={"medium"}
										label={
											tabIndex === tabs.length - 1
												? "Enviar"
												: "Próximo"
										}
										// onClick={handleNext}
										// IconLeft={IconNewWindow}
										iconHeight={14}
										iconWidth={16}
										className="bg-azul text-branco w-[35.733vw] h-[9.6vw] font-semibold text-[3.2vw] rounded-md"
									/>
								</div>
							</>
						)}
					</div>
				</div>
			</CustomForm>
		);
	}

	return (
		<CustomForm form={form}>
			<div
				className="d-flex flex-row d-flex flex-row justify-content-center position-relative pt-5 mt-5"
				id="contato"
			>
				<div className="talk-to-us d-flex flex-column block-content position-relative">
					<div className="main-icon text-center mx-auto">
						<img src={IconDompa} width={64} />
					</div>
					<div className="title text-96 text-center font-tt">
						Fala Conosco
					</div>

					{messageSent ? (
						<div className="py-5 my-5 d-flex justify-content-center align-items-center">
							<div
								className="font-tt text-32"
								style={{ color: "var(--blue-4)" }}
							>
								Mensagem enviada !
							</div>
						</div>
					) : (
						<>
							<TabTexts
								tabs={tabs}
								className="tabs d-flex flex-column debug4 justify-content-center pt-5"
								activeTab={activeTab}
								onTabChange={(tab, index) =>
									handleClick(tab, index)
								}
							/>

							{tabs?.length &&
								tabs.map((item, index) => (
									<div
										key={item.id}
										className="tab-container d-flex flex-column align-items-center gap-2 debug2 pt-5"
										data-state={
											tabIndex === index
												? "active"
												: "inactive"
										}
									>
										<div className="d-flex flex-row debug1 w-100">
											<FormFields
												form={form}
												fields={fields.filter(
													(field) =>
														field?.category ===
														activeTab?.id,
												)}
											/>
										</div>
									</div>
								))}

							<div className="submit-container d-flex justify-content-end pt-2">
								<Button
									template="blue"
									type={"submit"}
									size={"medium"}
									label={
										tabIndex === tabs.length - 1
											? "Enviar"
											: "Próximo"
									}
									// onClick={handleNext}
									// IconLeft={IconNewWindow}
									iconHeight={14}
									iconWidth={16}
								/>
							</div>
						</>
					)}
				</div>
			</div>
		</CustomForm>
	);
};

export default TalkToUs;
