import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Circles } from "../../assets/images/circles-2.svg";
import BackgroundImage from "../../assets/images/backgrounds/products.jpg";
import { ReactComponent as Pointer } from "../../assets/images/icons/pointer.svg";
import Hero from "../components/blocks/Hero/Hero";
import Button from "../components/layout/Button/Button";
import Page from "../components/layout/Page/Page";
import services from "../../services";
import ProductsKnowMore from "./blocks/ProductsKnowMore/ProductsKnowMore";
import ProductsMoreInfo from "./blocks/ProductsMoreInfo/ProductsMoreInfo";
import { useOvermind } from "../../overmind";
import nl2br from "react-nl2br";

const ProductsPage = ({ ...props }) => {
	const params = useParams();
	const productId = useMemo(() => params.productId, [params]);
	const data = useMemo(
		() => services.products.get(productId || "fazla"),
		[productId],
	);
	const app = useOvermind("app");

	const navigate = useNavigate();

	if (!data) return null;

	return (
		<Page>
			<Hero
				preTitle={
					<div
						className={`${
							app.state.isMobile ? null : "text-28 fw-bold"
						}`}
					>
						CONHEÇA MAIS SOBRE NOSSOS PRODUTOS
					</div>
				}
				title={
					<div
						className={`${
							app.state.isMobile
								? null
								: "text-128 fw-bold font-tt"
						}`}
					>
						{nl2br(data.name)}
					</div>
				}
				description={
					<div className={`${app.state.isMobile ? null : "text-28"}`}>
						{nl2br(data.mainDescription)}
					</div>
				}
				BackgroundImage={BackgroundImage}
				backgroundPositionY={app.state.isMobile ? "-4rem" : "-18.5rem"}
				backgroundPaddingBottom="8rem"
				paddingTop={"12rem"}
				circleTop={app.state.isMobile ? "4.5rem" : "22rem"}
				CirclesImage={Circles}
				circleRotate={0}
				circleRotateX={0}
				circleRotateY={0}
				circleTranslateX={app.state.isMobile ? "-100px" : "-240px"}
				// circleTranslateX={"-100px"}
				circleTranslateY={"-16rem"}
				actions={
					app.state.isMobile ? (
						<div className={`flex gap-[4.5vw]`}>
							<Button
								label="Contato"
								size={"big"}
								onClick={() => navigate(`/#contato`)}
								className="bg-azul text-branco w-[25vw] h-[5.87vw] rounded-md"
							/>
							<Button
								label="Nossos serviços"
								onClick={() => navigate(`/#servicos`)}
								template={"link"}
								size={"big"}
								IconRight={Pointer}
								className="text-azul"
							/>
						</div>
					) : (
						<>
							<Button
								label="Contato"
								size={"big"}
								onClick={() => navigate(`/#contato`)}
								className="bg-azul text-branco w-[94px] h-[22px] rounded-md"
							/>
							<Button
								label="Nossos serviços"
								onClick={() => navigate(`/#servicos`)}
								template={"link"}
								size={"big"}
								IconRight={Pointer}
								className="text-azul"
							/>
						</>
					)
				}
			/>

			<ProductsKnowMore data={data} />
			<ProductsMoreInfo data={data} />
			{/*<ClientDescription data={data} />*/}
			{/*<TalkToUs />*/}
		</Page>
	);
};

export default ProductsPage;
