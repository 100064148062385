import React, { useState, useMemo, useEffect, useCallback } from "react";

import IMG from "../../../assets/images/static/products/fazla.png";
import KIMG from "../../../assets/images/static/products/knowmore/fazla.png";

export const dataProductsFazla = {
	id: "fazla",
	name: "Faz Lá",
	title: `Sonhos que se tornam realidade com benefícios, promoções e experiências únicas`,
	description: `O Faz Lá é um clube de benefícios que conecta milhares de descontos, experiências únicas e campanhas promocionais, tudo no mesmo ambiente! Oferecemos planos mensal, anual e vitalício, permitindo com que o consumidor escolha a melhor opção para aproveitar tudo o que o Faz Lá tem a oferecer.`,
	image: IMG,
	users: "39.673",
	influencers: "12",

	mainDescription: `Sonhos que se tornam realidade com\nbenefícios, promoções e experiências únicas.`,

	partnerType: `Influenciadores`,

	knowMoreDescription: `O Faz Lá é um clube de benefícios que conecta milhares de descontos, experiências únicas e campanhas promocionais, tudo no mesmo ambiente! Oferecemos planos mensal, anual e vitalício, permitindo com que o consumidor escolha a melhor opção para aproveitar tudo o que o Faz Lá tem a oferecer.`,

	knowMoreImage2: KIMG,
	knowMoreTitle2: `Para\nInfluenciadores`,
	knowMoreDescription2: `o Faz Lá é o parceiro ideal. Criadores de conteúdo podem desenvolver campanhas personalizadas e impulsionar o engajamento de sua comunidade com facilidade. Nossa plataforma simplifica todo o processo, oferecendo suporte jurídico, automação de sorteios e ferramentas avançadas para acompanhamento de desempenho.`,

	knowMoreInfoDescription: `Ao se tornar membro, você ganha acesso a descontos incríveis, pode participar de campanhas promocionais, e ainda tem acesso a conteúdos e produtos exclusivos. Com o Faz Lá, concorrer a experiências inesquecíveis nunca foi tão fácil e acessível!`,
	website: `https://fazla.com.br/`,
};
