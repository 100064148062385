import React, {useState, useMemo, useEffect, useCallback} from "react"


import IMG from "../../../assets/images/static/clients/bello.jpg"

export const dataClientsBello =
	{
		id : "bello-futvolei",
		name : `Bello`,
		challenge : `Criar uma campanha que captasse recursos para combater a fome e que engajasse os participantes com uma premiação única e inovadora.`,
		image : IMG,
		solution : `Confira os resultados das nossas campanhas e veja como transformamos ideias em ações que geram engajamento e impacto real.`,
		solutionItems : [
			{
				description : `Parceria com o Instituto Orsi e o atleta Bello`,
			},
			{
				description : `Oferecimento de um PlayStation 5 folheado a ouro`,
			},
			{
				description : `Planejamento estratégico e execução de uma campanha`,
			},
		],
		results : `Recursos captados para apoiar o combate à fome, alto engajamento do público, motivado por um prêmio surpreendente e reforço do compromisso com ações que unem impacto social e criatividade.`,
		website : `https://www.google.com`,
	}



