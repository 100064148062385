import React from "react";
import { Col, FormGroup, Row, Tooltip } from "reactstrap";

const FormTemplateBootstrapDefault = ({
	form,
	field,
	fieldType,
	id,
	visible,
	enabled,
	touched,
	error,
	firstError,
	row,
	grid,
	...props
}) => {
	// console.log({visibles:form.visibles})

	// const RowCol = ({colProps, row=false, children}) => {
	// 	return row
	// 		? <Row className="form-field-row">
	// 			<Col {...colProps}>{children}</Col>
	// 	      </Row>
	// 		: <Col {...colProps}>{children}</Col>
	// }

	if (field.component) {
		return (
			<field.component
				componentProps={field.componentProps}
				{...{ field, form }}
				setValue={async ({ value }) => {
					await form.setFieldValue(field.name, value);
				}}
				getValue={() => {
					return form.values && form.values[field.name]
						? form.values[field.name]
						: null;
				}}
			/>
		);
	}

	const tooltipTarget = fieldType?.contained
		? `form-input-container-${field.name}`
		: `form-input-${field.name}`;
	// console.log(tooltipTarget)
	return (
		<>
			{/*{field.grid?.row && <Row className="form-field-row"/>}*/}
			<Col className="form-field-col debug3" {...grid}>
				{fieldType.noInput ? (
					<fieldType.component
						className={`
							form-field-input
							form-control
						`}
						id={id}
						form={form}
						field={field}
						name={field.name}
						placeHolder={" "}
					/>
				) : (
					<FormGroup
						floating
						className={`
						form-field-group
						
						form-field-type-${field?.type}
						${touched && !error ? "was-validated" : ""}
						${error ? "form-field-invalid" : "form-field-valid"}
						${touched ? "form-field-touched" : ""}
						${
							!enabled && !field.showDisabled
								? "form-field-disabled"
								: !enabled && field.showDisabled
								? ""
								: ""
						}
						debug6
					`}
						{...fieldType.formGroupProps}
					>
						<fieldType.component
							className={`
							form-field-input
							${touched && error ? "is-invalid" : ""}
							form-control
						`}
							id={id}
							form={form}
							field={field}
							name={field.name}
							placeHolder={" "}
							onChange={field.onChange}
							onFocus={() => form.setFocusField(field.name)}
							// onBlur={() => form.setFocusField('')}
							enabled={enabled}
						/>
						{/*
						VariationPlacement =
						"top-start" | "top-end" |
						"bottom-start" | "bottom-end" |
						"right-start" | "right-end" |
						"left-start" | "left-end";
					*/}
						{/*<br/>touched:{touched ? "SIM" : "NAO"}*/}
						{/*<br/>error:{error ? "SIM" : "NAO"}*/}
						{/*<br/>form.focusField:{form.focusField}*/}
						{/*<br/>field.name:{field.name}*/}
						{/*<br/>firstError:{firstError}*/}
						{/*<br/>value:{form?.values ? form?.values[field.name] : null}*/}
						{/*<br/>tooltipTarget:{tooltipTarget}*/}
						<>
							{tooltipTarget && (
								<Tooltip
									key={error}
									isOpen={
										error &&
										form.focusField === field.name &&
										firstError &&
										form.firstError === field.name &&
										(!field.category ||
											(field.category &&
												form.activeCategory ===
													field.category))
									}
									target={
										document.getElementById(tooltipTarget)
											? tooltipTarget
											: null
									}
									placement={"top"}
									className={`${form.cssSkin} form-field-tooltip`}
									container={document.body}
								>
									<div>{error}</div>
								</Tooltip>
							)}
						</>
					</FormGroup>
				)}
			</Col>
		</>
	);
};

export default FormTemplateBootstrapDefault;
