import React, { useState, useMemo, useEffect, useCallback } from "react";

import IMG from "../../../assets/images/static/products/dompapromo.png";
import KIMG from "../../../assets/images/static/products/knowmore/dompapromo.jpg";

export const dataProductsDompaPromo = {
	id: "dompa-promo",
	name: "Dompa Promo",
	title: "Soluções completas e inovadoras para seu negócios",
	description: `Dompa Promo foi criado para atender empresas de todos os segmentos e tamanhos, oferecendo soluções completas e eficientes para campanhas promocionais. Com foco em alinhar o núcleo de negócios às estratégias de marketing, desenvolvemos ações personalizadas que engajam públicos e fortalecem marcas.`,
	image: IMG,
	users: "36.978",
	influencers: "56",

	mainDescription: `Soluções completas e inovadoras para seu negócios`,

	partnerType: `Empresas`,

	knowMoreDescription: `Dompa Promo foi criado para atender empresas de todos os segmentos e tamanhos, oferecendo soluções completas e eficientes para campanhas promocionais. Com foco em alinhar o núcleo de negócios às estratégias de marketing, desenvolvemos ações personalizadas que engajam públicos e fortalecem marcas.`,

	knowMoreImage2: KIMG,
	knowMoreTitle2: `Administração e Promoção`,
	knowMoreDescription2: `Ao fazer parceria com a Dompa, a empresa pode cadastrar sua loja e participar de campanhas promocionais exclusivas, oferecendo aos clientes oportunidades únicas que impulsionam resultados. Cuidamos de toda a administração e promoção das campanhas, enquanto a empresa apenas se cadastra e divulga para seus clientes.`,

	knowMoreInfoDescription: `Simples, estratégico e eficaz!`,
	website: `https://dompashop.com.br/`,
};
