import React, { useState, useEffect } from "react";
import {
	isValidCnpj,
	isValidCpf,
	isValidCpfCnpj,
	isValidEmail,
	isValidOption,
	isValidPhoneNumber,
	isValidValue,
} from "./Validations";
import InputText from "./Inputs/Text";
// import { Fieldset } from 'primereact/fieldset';
import FormTemplateBootstrapDefault from "./Templates/BootstrapDefault";
import InputSelect2 from "./Inputs/Select2";
import InputPhone from "./Inputs/Phone";
import InputCpf from "./Inputs/Cpf";
import InputTextArea from "./Inputs/TextArea";
import { Col, Row, Container } from "reactstrap";
import InputTitle from "./Inputs/Title";
import InputCnpj from "./Inputs/Cnpj";
import InputCpfCnpj from "./Inputs/CpfCnpj";
import { useOvermind } from "../../overmind";
import "./index.css";

export const registeredFields = [
	{ type: "component", noInput: true },
	{
		type: "title",
		component: InputTitle,
		noInput: true,
		debugValue: "titulo",
	},
	{
		type: "text",
		component: InputText,
		defaultValidator: isValidValue,
		debugValue: "text",
	},

	// {type:'color', component:InputColor, defaultValidator:isValidValue, debugValue:'text'},
	{
		type: "textarea",
		component: InputTextArea,
		defaultValidator: isValidValue,
		debugValue: "text",
	},

	{
		type: "email",
		component: InputText,
		defaultValidator: isValidEmail,
		debugValue: "email@domain.com",
	},
	// {type:'file-avatar', component:InputFileAvatar, defaultValidator:isValidValue, debugValue:'dataurl'},
	// {type:'file-upload', component:InputFileUpload, defaultValidator:isValidValue, debugValue:'dataurl'},
	// {type:'image-gallery', component:InputImageGallery, defaultValidator:isValidValue, debugValue:'dataurl'},
	// {type:'audio-upload', component:InputAudioUpload, defaultValidator:isValidValue, debugValue:'dataurl'},
	// {type:'file-lottie', component:LottieUpload, defaultValidator:isValidValue, debugValue:'dataurl'},
	{
		type: "select2",
		component: InputSelect2,
		defaultValidator: isValidOption,
		contained: true,
		debugValue: "1",
	},
	// {type:'select-item', component:InputSelectItem, defaultValidator:isValidOption, debugValue:'dataurl'},
	// {type:'range-slider', component:InputRangeSlider, defaultValidator:isValidNumber, debugValue:1},
	{
		type: "phone",
		component: InputPhone,
		defaultValidator: isValidPhoneNumber,
		debugValue: "11960111618",
	},
	// {type:'password', component:InputPassword, defaultValidator:isValidValue, debugValue:'aaaaaaaa'},
	{
		type: "cpf",
		component: InputCpf,
		defaultValidator: isValidCpf,
		debugValue: "31530056802",
	},
	{
		type: "cnpj",
		component: InputCnpj,
		defaultValidator: isValidCnpj,
		debugValue: "31530056802",
	},
	{
		type: "cpfcnpj",
		component: InputCpfCnpj,
		defaultValidator: isValidCpfCnpj,
		debugValue: "31530056802",
	},
	// {type:'date', component:InputDate, defaultValidator:isValidDate, debugValue:'27/08/1982'},
	// {type:'date-range', component:InputDateRange, defaultValidator:isValidDateRange, debugValue:'27/08/1982'},
	// {type:'switch', component:InputSwitch, defaultValidator:isValidValue, debugValue:true},
	// {type:'component-array', component:InputComponentArray, defaultValidator:isValidOptions, debugValue:[]},
	// {type:'form-array', component:InputComponentArray, defaultValidator:isValidOptions, debugValue:[], defaultProps:{componentArray:ArrayComponentFormInput}},
	// {type:'money', component:InputMoney, defaultValidator:isValidMoney, debugValue:[]},
	// {type:'check-group', component:InputCheckGroup, defaultValidator:isValidOption, debugValue:[]},
	// {type:'number', component:InputNumber, defaultValidator:isValidNumber, debugValue:0},
	// {type:'percent', component:InputPercent, defaultValidator:isValidPercent, debugValue:0},
	// {type:'ww-select-model', component:InputWWSelectModel, defaultValidator:isValidValue, debugValue:'text'},
	// {type:'table', component:InputWWSelectTable, defaultValidator:isValidValue, debugValue:'text'},
];

const Fieldset = ({ children, ...props }) => {
	return <div {...props}>{children}</div>;
};

const FormFields = ({
	form,
	Template = FormTemplateBootstrapDefault,
	fields,
	...props
}) => {
	const fieldSets = [{ rows: [[]] }];
	fields.map((field, fieldIndex) => {
		const fieldType = registeredFields.find((el) => el.type === field.type);
		if (!fieldType) {
			console.log("Field type not registered: " + field.type);
			return `field.type="${field.type} not registered as field"`;
		}

		const visible = form.visibles[field.name];
		const enabled = field.isEnabled
			? field.isEnabled({
					value: form.values[field.name],
					props: field,
					values: form.values,
			  })
			: true;

		if (form?.readOnly) {
			field.isEnabled = ({ values }) => false;
			field.showDisabled = true;
		}

		const touched = form.touched[field.name];
		const error = form.errors[field.name];
		const firstError = form.firstError;
		// const focusField = form.focusField
		const id = `form-input-${field.name}`;
		const { row, ...grid } = {
			...{ sx: 12, sm: 12 },
			...field.grid,
		};
		if (visible === false) return null;

		const fieldProps = {
			form,
			field,
			fieldType,
			visible,
			touched,
			error,
			firstError,
			enabled,
			id,
			row,
			grid,
		};

		//old version, no fieldsets
		// if (field.grid?.row) {
		// 	rows.push([])
		// }
		// rows[rows.length-1].push(<Template key={field.name} {...fieldProps} />)

		if (field.fieldSet) {
			if (
				fieldSets[fieldSets.length - 1]?.config?.legend !==
				field?.fieldSet?.legend
			)
				fieldSets.push({ config: field.fieldSet, rows: [[]] });
		}

		const currentFieldSet = fieldSets[fieldSets.length - 1];
		const currentRows =
			currentFieldSet.rows[currentFieldSet.rows.length - 1];
		if (field.grid?.row) {
			currentRows.push([]);
		}

		currentRows.push(<Template key={fieldIndex} {...fieldProps} />);
	});

	const app = useOvermind("app");

	return Object.values(fieldSets).map((fieldset, fieldIndex) => {
		if (!fieldset.config) {
			return fieldset.rows.map((row, rowIndex) => {
				console.log(fieldset);
				return (
					<Row
						key={rowIndex}
						className={` ${
							app.state.isMobile
								? "row-custom text-nowrap"
								: "w-100 form-input-row ms-0"
						}`}
					>
						{row}
					</Row>
				);
			});
		}

		return (
			<Fieldset {...fieldset.config} key={fieldIndex}>
				{fieldset.rows.map((row, rowIndex) => (
					<Row key={rowIndex}>{row}</Row>
				))}
			</Fieldset>
		);
	});
	// return rows.map(row => {
	// 	return <Fieldset><Row>{row}</Row></Fieldset>
	// })
};

export default FormFields;
