import React, { useState, useMemo, useEffect, useCallback } from "react";

// import {ReactComponent as CirclesImage1} from "../../../../assets/images/circles.svg"
// import {ReactComponent as CirclesImage2} from "../../../../assets/images/circles-2.svg"
import "./service-benefits.scss";
import Bene1 from "../../../../assets/images/static/benefits/b1.jpg";
import Bene2 from "../../../../assets/images/static/benefits/b2.jpg";
import Bene3 from "../../../../assets/images/static/benefits/b3.jpg";
import Bene4 from "../../../../assets/images/static/benefits/b4.jpg";
import Bene5 from "../../../../assets/images/static/benefits/b5.jpg";
import { ReactComponent as IconScreen } from "../../../../assets/images/icons/screen.svg";

import nl2br from "react-nl2br";
import Circles from "../../../components/blocks/Circles";
import { ReactComponent as CirclesImage } from "../../../../assets/images/circles-5.svg";
import { useOvermind } from "../../../../overmind";

const BenefitImages = [Bene1, Bene2, Bene3, Bene4, Bene5];

const Card = ({
	Image,
	title,
	description,
	counter,
	onClick,
	command,
	image,
	active,
	descriptionPaddingRight,
	actions,
}) => {
	const app = useOvermind("app");

	if (app.state.isMobile) {
		return (
			<div
				className={`w-full relative bg-cover bg-center px-[6.4vw] rounded-xl flex flex-col items-end justify-center text-branco overflow-hidden 
        ${active ? "min-h-[66.8vw]" : "min-h-[21.333vw] "} 
        transition-all duration-300 ease-in-out`}
				style={{ backgroundImage: `url(${Image})` }}
				onClick={onClick}
			>
				<div className="absolute inset-0 bg-gradient-to-t from-azul-3 from-10% to-100% z-10 rounded-xl" />
				<div className="flex items-center z-10">
					<div>
						<IconScreen className="w-[6.933vw] h-[7.2vw]" />
					</div>
					<div className="text-[10.667vw] font-semibold ">
						{counter}
					</div>
				</div>
				{active && (
					<div className="text-start self-start space-y-[3.2vw] relative z-10">
						<div className="text-[8.533vw] font-semibold ">
							{nl2br(title)}
						</div>
						<div className="text-[4vw]">{nl2br(description)}</div>
					</div>
				)}
			</div>
		);
	}

	return (
		<div
			className={`benefits-card d-flex flex-column position-relative debug5 ${
				app.state.isMobile || active ? "active" : "inactive"
			}`}
			onClick={onClick}
		>
			<div
				className="position-absolute w-100 h-100 card-image debug3 z-n1 no-pointer-event"
				style={{ backgroundImage: `url(${Image})` }}
			/>
			<div className="position-absolute w-100 h-100 bg-gradient debug3 z-0 no-pointer-event" />

			<div className="icon-container flex-grow-0 debug1 z-1 d-flex align-items-center w-100 gap-4">
				<div className="card-icon">
					<IconScreen width={44} height={45} />
				</div>
				<div className="card-counter font-tt">{counter}</div>
			</div>

			<div className="card-title flex-grow-0 debug2 z-1 font-tt">
				{nl2br(title)}
			</div>
			<div
				className="card-description flex-grow-0 debug2 z-1"
				style={{ paddingRight: descriptionPaddingRight }}
			>
				{nl2br(description)}
			</div>
		</div>
	);
};

const ServiceBenefits = ({ data, actions }) => {
	const cards = data.benefits;

	const [activeIndex, setActiveIndex] = useState(null);

	const app = useOvermind("app");

	if (app.state.isMobile) {
		return (
			<div className="py-[13.333vw] text-preto leading-none px-[15px]">
				<div className="">
					<div className="">
						<div className="text-[9.6vw] font-titillium font-bold">
							Benefícios
						</div>
						<div className="text-[4vw] font-roboto py-[6.4vw]">
							{data.benefitsSubTitle}
						</div>
						<div className="space-y-[3.2vw]">
							{cards &&
								cards.map((card, index) => (
									<Card
										key={index}
										onClick={() =>
											setActiveIndex(
												index === activeIndex
													? null
													: index,
											)
										}
										Image={BenefitImages[index]}
										counter={index + 1}
										active={activeIndex === index}
										{...card}
									/>
								))}
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="service-benefits debug1 d-flex flex-row justify-content-center position-relative">
			<div className="d-flex flex-column block-content ">
				{/*<div className="position-absolute boxed bg-gradient-1 z-2"/>*/}
				{/*<div className="position-absolute boxed bg-gradient-2 z-2"/>*/}

				<div className="d-flex flex-column content z-2">
					<div className="flex-grow-0 debug2 title font-tt text-96">
						Benefícios
					</div>
					<div className="flex-grow-0 debug2 sub-title text-28">
						{data.benefitsSubTitle}
					</div>
					<div className="cards d-flex mb-row-column">
						{cards &&
							cards.map((card, index) => (
								<Card
									onClick={() => setActiveIndex(index)}
									key={index}
									Image={BenefitImages[index]}
									counter={index + 1}
									active={activeIndex === index}
									{...card}
								/>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ServiceBenefits;
