import React, {useState, useMemo, useEffect, useCallback} from "react"


import IMG from "../../../assets/images/static/clients/corinthians.png"

export const dataClientsCorinthians =
	{
		id : "corinthians",
		name : `Corinthians`,
		challenge : `Desenvolvimento em apenas 48 horas de  uma plataforma que integrasse sorteios e um dashboard gerenciável para um evento especial no Corinthians e a garantia de segurança e conformidade com a LGPD ao validar os participantes no local do evento.`,
		image : IMG,
		solution : `Confira os resultados das nossas campanhas e veja como transformamos ideias em ações que geram engajamento e impacto real.`,
		solutionItems : [
			{
				description : `Criação de uma plataforma inovadora que permitia o check-in no local`,
			},
			{
				description : `Validação automática dos dados dos associados por meio de IA`,
			},
			{
				description : `Acompanhamento dos organizadores e garantiu eficiência no gerenciamento do sorteio`,
			},
		],
		results : `Campanha lançada em prazo com integração eficiente das concessionárias com validação automatizada segura e confiável e uma experiência exclusiva entregue com sucesso, fortalecendo o impacto da marca.`,
		website : `https://www.corinthians.com.br/`,
	}



