// import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/app.scss";

// import {AnimatePresence} from "framer-motion";
// import {AnimatePresence} from "framer-motion/dist/framer-motion";
import { environment, isLocalhost } from "./configuration";
import Routes from "./routes/routes";
import { useWindowSize } from "./helpers/uses/windowsize";
import { useOvermind } from "./overmind";
const _ = require("lodash");

const App = (props) => {
	// const navigate = useNavigate()
	const app = useOvermind("app");

	const location = useLocation();
	const navigate = useNavigate();
	const windowSize = useWindowSize();
	const lastPathnameRef = useRef(location.pathname); // Stores the last URL

	useEffect(() => {
		const lastPathname = lastPathnameRef.current;

		// Compare the last URL with the current one
		if (!lastPathname.includes("/clientes")) {
			// Scroll to top if the last URL does not include '/clientes'
			window.scrollTo({ top: 0, behavior: "smooth" });
		}

		// Update the last URL for the next render
		lastPathnameRef.current = location.pathname;

		if (location.hash) {
			try {
				const hashname = location.hash.slice(1); // Remove the "#" from the hash
				document
					.getElementById(hashname)
					?.scrollIntoView({ behavior: "smooth" });
			} catch (e) {
				console.error(e);
			}
		}
	}, [location]);

	useEffect(() => {
		if (!windowSize.width || !windowSize.height) return;
		// console.log(`${windowSize.width}x${windowSize.height}`)
		app.actions.setIsMobile(windowSize.width <= 1279);
	}, [windowSize.width, windowSize.height]);

	useEffect(() => {
		if (environment !== "production") {
			const handleKeyPress = (e) => {
				if (e.keyCode == 39) {
					// 39 = ' (press key single quote to see div outline borders for debug)
					document.body.classList.toggle("debug-on");
				}
			};
			document.body.addEventListener("keypress", handleKeyPress);
			return () =>
				document.body.removeEventListener("keypress", handleKeyPress);
		}
	}, []);

	return (
		<React.Fragment>
			<Routes />
		</React.Fragment>
	);
};

export default App;
