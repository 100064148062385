const labelBs = 3

export const customStyles = (cssSkin, form) => {
	const cssSkins = {
		"form-admin" : {
			inputFontFamily : "var(--input-font-family)",
			inputLabelFontFamily : "var(--input-label-font-family)",
			inputBorderRadius : "var(--form-input-border-radius)",
			inputBorderColor : "var(--form-input-border-color)",
			inputBoxShadow : "var(--form-input-box-shadow)",
			textColor : "var(--black)",
			borderColor : form?.readOnly ? "transparent" : "#9CA4AB",
			boxShadow : "var(--vl-box-shadow-gray)",
			inputBackground : "var(--form-input-background)",
			controlPadding : "1rem 0 0 0",
		},
		"form-admin-round" : {
			inputFontFamily : "var(--input-font-family)",
			inputLabelFontFamily : "var(--input-label-font-family)",
			inputBorderRadius : "1.5rem",
			inputBorderColor : "transparent",
			inputBoxShadow : "none",
			textColor : "var(--white)",
			borderColor : "transparent",
			boxShadow : "var(--vl-box-shadow-gray)",
			inputBackground : "var(--black-2)",
			controlPadding : "1.2rem .25rem .25rem .75rem",
		},
	}

	const skin = cssSkins[cssSkin]

	return {

		control: (provided, state) => ({
			...provided,
			borderWidth:0,
			boxShadow: state.isFocused ? skin.inputBoxShadow : "none",
			minHeight:'4rem',
			// padding:'0px',
			margin:'0px',
			// minHeight:1,
			outline:'0px',
			borderRadius:skin.inputBorderRadius,
			backgroundColor:skin.inputBackground,
			color:skin.textColor,
			padding:skin.controlPadding,
			// zIndex:9999999,

		}),

		container: (provided, state) => {
			// console.log({provided, state})
			return {
				...provided,
				margin:'0px',
				padding:'0',
				// borderRadius:(state.isFocused || state?.selectProps?.alwaysRounded) ? 6 : 0,
				borderRadius:(state.isFocused || 1) ? skin.inputBorderRadius : 0,
				backgroundColor : "transparent",
				//border:state.selectProps.fieldError ? 'solid 2px var(--bs-orange)' : 'solid 2px green' ,
				display:"grid",
				// minHeight:'3.6rem',
				// border:"solid 1px",
				border:`solid 1px ${skin.inputBorderColor}`,
				overflow: "visible",
				borderColor:skin.borderColor,
				// borderTopColor:state.isFocused ? skin.borderColor : 'transparent',
				// borderLeftColor:state.isFocused ? skin.borderColor : 'transparent',
				// borderRightColor:state.isFocused ? skin.borderColor : 'transparent',


			};
		},

		menu: (provided, state) => {
			return {
				...provided,
				borderRadius:'4px',
				padding:'0px 0px 0px 0px',
				border:'solid 1px var(--vl-purple-light)',

			};
		},

		menuPortal: base => ({
			...base,
			zIndex: 9999999,
			// left:0,
			// top:0,
			// minWidth:'100%',
		}),

		menuList: (provided, state) => {
			return {
				...provided,
				padding:'6px 6px 4px 6px',
			};
		},

		option: (provided, state) => {
			//console.log('option', {state, provided})
			return {
				...provided,
				margin:'0px 0px 2px 0px',
				borderRadius:'2px',
				backgroundColor:state.isSelected ? 'var(--black)' : null,
				color:state.isSelected ? 'white' : 'var(--bs-black)',

				//outline : state.isFocused ? 'solid 1px var(--bs-orange)' : false,
				border:state.isFocused ?
						(state.isSelected ? 'solid 1px black' : 'solid 1px black')
					: 'solid 1px transparent',
				":hover" : {
					border:(state.isSelected&&state.isFocused) ? 'solid 1px black' : 'solid 1px black',
					backgroundColor:state.isSelected ? false : 'white',
					color:state.isSelected ? "white" : 'black',
					//border:'solid 1px #f7d8c4',
				},
				":active" : {
					//border:'solid 4px white' : 'solid 2px blue',
					backgroundColor:'gray',
					//border:'solid 1px red',
					color:'black',
					//border:'solid 1px #f7d8c4',
				},
			};
		},

		input: (provided, state) => {
			return {
				...provided,
				margin:'0px',
				// border:'solid 1px red',
				height:null,
				marginTop: "0em",
				paddingTop:'0px',
				padding:"0px 0px 0px 0px",
				marginLeft: ".75rem",
				paddingLeft:'0px',
				border:"solid 0px green",
				color:skin.textColor,
				fontFamily : skin.inputFontFamily,

			};
		},

		// inputContainer: (provided, state) => {
		// 	return {
		// 		...provided,
		// 		color:skin.textColor,
		// 	};
		// },

		indicatorsContainer: (provided, state) => {
			return {
				...provided,
				// margin:"-.75rem .5rem 0px 0px",
				padding:"0",
				// border:'solid 1px purple',
			};
		},

		dropdownIndicator: (provided, state) => {
			return {
				...provided,
				padding:"0rem .6rem 0rem 0rem ",
				margin:"-.75rem 0rem 0rem 0rem ",
				//fill:state.selectProps.fieldError ? 'var(--bs-orange) !important' : 'var(--bs-gray) !important' ,
				// border:'solid 1px red',
				visibility:state.selectProps.isDisabled ? 'hidden' : 'unset',
				"&:hover" : {
					color:'var(--vl-pink-light)'
				}
			};
		},

		indicatorSeparator: (provided, state) => {
			return {
				...provided,
				padding:'0px 0px 0px 0px',
				// margin:'0.8rem 0.8rem 0.8rem 0.8rem',
				backgroundColor:state.selectProps.fieldError ? '#f7d8c4 !important' : '#f4f0ec !important' ,
				//opacity:state.selectProps.fieldError ? '0.5' : false ,
				// display:state.selectProps.fieldError ? 'none' : false ,
				display:'none',
				//borderColor:'red',
				//border:'0px !important	',
			};
		},

		clearIndicator: (provided, state) => {
			return {
				...provided,
				// padding:'0',
				// color:"var(--vl-pink)",
				// ":hover" : {
				// 	color:"var(--vl-orange)",
				// },

				// display:'none',
			};
		},

		valueContainer: (provided, state) => {
			return {
				...provided,
				height:'100%',
				border:'solid 0px red',
				color:'green',
				padding:'0 0 0 .5rem',
				margin:'0px',
				minWidth:1,
				overflow:'unset',

			};
		},

		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.6180 : 1;
			const transition = 'opacity 300ms';
			//console.log(provided)
			return {
				...provided,
				// opacity,
				transition,

				width:'100%',
				paddingLeft: ".1rem",
				color:skin.textColor,
			};
		},

		placeholder: (provided, state) => {
			return {
				...provided,
				marginLeft:'0px',
				marginRight:'0px',

				position: "absolute",
				top: state.hasValue || state.selectProps.inputValue ? -12 : "0%",
				left: ".2rem",
				transition: "top 0.2s, font-size 0.2s",
				// opacity: state.hasValue || state.selectProps.inputValue ? .4 : 1,
				color: state.hasValue || state.selectProps.inputValue ? skin.textColor : skin.textColor,
				fontWeight: state.hasValue || state.selectProps.inputValue ? "bold" : "normal",
				// transform: state.hasValue || state.selectProps.inputValue
				// 	? "scale(0.85) "
				// 	: "",
				fontSize: (state.hasValue || state.selectProps.inputValue) ? "1rem" : "1.25rem",

				padding:(state.hasValue || state.selectProps.inputValue) ? '0 0 0 .5rem' : '0rem 0 0 .5rem' ,

				// color:skin.textColor,
				width:'100%',
			};
		},

		multiValue: (provided, state) => {
			return {
				...provided,
				backgroundColor:'var(--blue-4)',
				// padding:'2px 2px 2px 2px',
				// margin:'2px 2px 2px 0px',
				// borderRadius:'0px 8px 8px 0px ',
				borderRadius:`${labelBs}px`,
				marginTop:'0.75em',
				marginBottom:'0.5em',
				":hover" : {
					// border:'solid 1px #f7d8c4',
				},
			};
		},

		multiValueLabel: (provided, state) => {
			return {
				...provided,
				// padding:'0px 0px 0px 0px',
				// margin:'0px 0px 0px 0px',
				// color:false,
				// borderRadius:`${labelBs}px 0px 0px ${labelBs}px`,
				// backgroundColor:'var(--vl-purple-light)',
				color:'var(--white)',
			};
		},

		multiValueRemove: (provided, state) => {
			//console.log('state', {state, provided})
			return {
				...provided,

				":hover" : {
					color:'white',
					//border:'solid 1px white',
					//borderColor:'blue !important',
					// opacity:'0.3180',
					// backgroundColor:'var(--vl-red-light)',
				},

				//color: 'var(--bs-white)',
				//opacity:'0.6180',
				color:'var(--black)',
				// backgroundColor:'var(--vl-purple-light)',
				borderRadius:`0px ${labelBs}px ${labelBs}px 0px`,
				borderColor:'var(--white)',
			};
		},

	}
}
