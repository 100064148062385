import React, {useState, useMemo, useEffect, useCallback} from "react"

import {ReactComponent as CirclesImage} from "../../../assets/images/circles.svg"

const Circles = (
	{
		className,
		Image = CirclesImage,
		rotate=0,
		rotateX=0,
		rotateY=0,
		translateX=0,
		translateY=0,
		top,
		bottom,
		right,
		left,
		width=606,
		height=606,
		...props
	}
) => {

	return (
		<div className={`circles-svg position-absolute ${className}`}
			 style={{
				 width, height, left, right, top, bottom,
				 transform:`translateX(${translateX}) translateY(${translateY})`
			 }}
		>
			<Image
				width={"auto'"}
				height={"100%"}
				style={{
					transform:`rotateX(${rotateX}deg) rotateY(${rotateY}deg) rotate(${rotate}deg)`,
					transformOrigin:"center",
				}}
			/>
		</div>
	)
}

export default Circles;
