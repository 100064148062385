import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Circles } from "../../assets/images/circles-2.svg";
import BackgroundImage from "../../assets/images/backgrounds/home.jpg";
import { ReactComponent as Pointer } from "../../assets/images/icons/pointer.svg";
import Hero from "../components/blocks/Hero/Hero";
import OurClients from "../components/blocks/OurClients/OurClients";
import WhoWeAre from "../components/blocks/WhoWeAre/WhoWeAre";
import OurServices from "../components/blocks/OurServices/OurServices";
import DompaMedia from "../components/blocks/DompaMedia/DompaMedia";
import Cases from "../components/blocks/Cases/Cases";
import Products from "../components/blocks/Products/Products";
import TalkToUs from "../components/blocks/TalkToUs/TalkToUs";
import Button from "../components/layout/Button/Button";
import Page from "../components/layout/Page/Page";
import { useOvermind } from "../../overmind";

const HomePage = ({ ...props }) => {
	const navigate = useNavigate();
	const app = useOvermind("app");
	return (
		<Page>
			<Hero
				isHome={true}
				title={
					<div
						className={`${
							app.state.isMobile ? null : "text-96 fw-bold"
						}`}
					>
						Revolucionando o<br />
						mercado com soluções
						<br />
						inteligentes
					</div>
				}
				description={
					<div className={`${app.state.isMobile ? null : "text-28"}`}>
						Lideramos a inovação em campanhas promocionais com uma
						plataforma que utiliza inteligência artificial e
						tecnologia de ponta para criar experiências únicas e
						impulsionar marcas.
					</div>
				}
				BackgroundImage={BackgroundImage}
				paddingTop={"22rem"}
				circleTop={app.state.isMobile ? "8rem" : "22rem"}
				CirclesImage={Circles}
				circleRotate={0}
				circleRotateX={0}
				circleRotateY={0}
				circleTranslateX={"-160px"}
				actions={
					app.state.isMobile ? (
						<div className={`flex gap-[4.5vw]`}>
							<Button
								label="Contato"
								size={"big"}
								onClick={() => navigate(`/#contato`)}
								className="bg-azul text-branco w-[25vw] h-[5.87vw] rounded-md"
							/>
							<Button
								label="Nossos serviços"
								onClick={() => navigate(`/#servicos`)}
								template={"link"}
								size={"big"}
								IconRight={Pointer}
								className="text-azul"
							/>
						</div>
					) : (
						<>
							<Button
								label="Contato"
								size={"big"}
								onClick={() => navigate(`/#contato`)}
								className="bg-azul text-branco w-[94px] h-[22px] rounded-md"
							/>
							<Button
								label="Nossos serviços"
								onClick={() => navigate(`/#servicos`)}
								template={"link"}
								size={"big"}
								IconRight={Pointer}
								className="text-azul"
							/>
						</>
					)
				}
				// circleTranslateX={"-100px"}
				circleTranslateY={"-100px"}
			/>
			<OurClients />
			<WhoWeAre />
			<OurServices />
			<DompaMedia />
			<Cases />
			<Products />
			<TalkToUs />
		</Page>
	);
};

export default HomePage;
