const _ = require('lodash')

export  const setIsMobile = ( {state}, isMobile ) => {
	state.app.isMobile = isMobile
	if (isMobile)
		document.body.classList.add('is-mobile')
	else
		document.body.classList.remove('is-mobile')

	console.log(`isMobile set to ${isMobile.toString()}`)
}

