import React, { useState, useMemo, useEffect, useCallback } from "react";
import Marquee from "react-fast-marquee";
import LogoBYD from "../../../../assets/images/company-logos/byd.png";
import Timao from "../../../../assets/images/company-logos/timao.png";
import Engemon from "../../../../assets/images/company-logos/engemon.png";
import SantoMercado from "../../../../assets/images/company-logos/santomercado.png";
import SantoMercadoMobile from "../../../../assets/images/company-logos/SantoMercado.svg";
import BYDMobile from "../../../../assets/images/company-logos/BYD.svg";
import EngemonMobile from "../../../../assets/images/company-logos/Engemon.svg";
import CorinthiansMobile from "../../../../assets/images/company-logos/Corinthians.svg";
import { cancelEvent, cancelEventPropagation } from "../../../../helpers/utils";
import "./our-clients.scss";
import { useOvermind } from "../../../../overmind";

const OurClients = ({ ...props }) => {
	const items = useMemo(
		() => [
			{
				label: "BYD",
				image: LogoBYD,
				mobile: BYDMobile,
				href: "http://google.com",
			},
			{
				label: "Cortinthians",
				image: Timao,
				mobile: CorinthiansMobile,
				href: "http://google.com",
			},
			{
				label: "engemon",
				image: Engemon,
				mobile: EngemonMobile,
				href: "http://google.com",
			},
			{
				label: "Santo Mercado",
				image: SantoMercado,
				mobile: SantoMercadoMobile,
				href: "http://google.com",
			},
		],
		[],
	);

	const app = useOvermind("app");

	if (app.state.isMobile) {
		return (
			<div className="py-[10vw]">
				<div className="space-y-[10vw]">
					<div className="text-[6.4vw] font-titillium font-semibold text-center">
						Veja quem já está conosco
					</div>
					<Marquee
						className="marquee-logos debug2 flex"
						autoFill={true}
						style={{ flexBasis: "50%" }}
						pauseOnHover={true}
						speed={100}
					>
						{items &&
							items.map((item) => (
								<a
									key={item.label}
									href={item.href}
									onClick={(e) => cancelEventPropagation(e)}
									target={"_blank"}
									title={item.label}
								>
									<div className="w-[15.2vw] h-[9.067vw] mx-[6.4vw] debug2 grayscale">
										<img
											loading="lazy"
											className={`w-full h-full object-contain debug3`}
											alt={item.label}
											src={item.image}
										/>
									</div>
								</a>
							))}
					</Marquee>
				</div>
			</div>
		);
	}

	return (
		<div className="d-flex flex-row d-flex flex-row justify-content-center position-relative pt-5 mt-5">
			<div className="d-flex mb-row-column block-content gap-4">
				<div
					className="flex-grow-1 debug2 text-60 font-tt mb-text-center "
					style={{ flexBasis: "50%", fontWeight: 600 }}
				>
					Veja quem já está conosco
				</div>
				<Marquee
					className="marquee-logos debug2"
					autoFill={true}
					style={{ flexBasis: "50%" }}
					pauseOnHover={true}
					speed={100}
				>
					{items &&
						items.map((item) => (
							<a
								key={item.label}
								href={item.href}
								onClick={(e) => cancelEventPropagation(e)}
								target={"_blank"}
								title={item.label}
							>
								<div className="item debug2">
									<div
										className="image debug3 grayscale"
										style={{
											backgroundImage: `url(${item.image})`,
										}}
									/>
								</div>
							</a>
						))}
				</Marquee>
			</div>
		</div>
	);
};

export default OurClients;
