import React, { useState, useMemo, useEffect, useCallback } from "react";

import "./dompa-media.scss";
import { ReactComponent as IconNewWindow } from "../../../../assets/images/icons/new-window.svg";
// import {ReactComponent as Circle} from "../../../../assets/images/circles-3.svg"

import UOL from "../../../../assets/images/static/media/uol.png";
import Polinize from "../../../../assets/images/static/media/polinize.png";
import SalaNoticia from "../../../../assets/images/static/media/salanoticia.png";
import Valor from "../../../../assets/images/static/media/valor.png";

import NewsOne from "../../../../assets/images/static/media/news/one.png";
import NewsTwo from "../../../../assets/images/static/media/news/two.png";
import NewsThree from "../../../../assets/images/static/media/news/three.jpg";
import Button from "../../layout/Button/Button";
import { useOvermind } from "../../../../overmind";

const DompaMedia = ({ ...props }) => {
	const logos = useMemo(
		() => [
			{
				label: "UOL",
				image: UOL,
				href: "https://www.uol.com.br/tilt/noticias/redacao/2024/02/21/uso-inteligencia-artificial-golpes-virtuais.htm?cmpid=copiaecola",
				width: "12.8vw",
				height: "4.8vw",
			},
			{
				label: "Valor",
				image: Valor,
				href: "https://bnldata.com.br/oito-dicas-para-organizar-sorteios-bem-sucedidos/",
				width: "15.467vw",
				height: "4.8vw",
			},

			{
				label: "Sala da Notícia",
				image: SalaNoticia,
				href: "https://bnldata.com.br/oito-dicas-para-organizar-sorteios-bem-sucedidos/",
				width: "15.467vw",
				height: "4.8vw",
			},
			{
				label: "Polinize",
				image: Polinize,
				href: "https://conteudo.polinize.com/dompa-anuncia-lancamento-de-aplicativo-de-gamificacao-que-visa-premiar-usuarios-em-jornadas-de-autoconhecimento/",
				width: "22.933vw",
				height: "4.8vw",
			},
		],
		[],
	);

	const news = useMemo(
		() => [
			{
				title: "Você não sabe, mas estes golpes usam IA para enganar você; aprenda a evitar",
				description:
					"Da ligação à mensagem de texto via WhatsApp, os golpes financeiros vêm se aperfeiçoando a cada dia. E a nova abordagem da vez é usar IA (Inteligência Artificial) para ludibriar as vítimas, já que a estratégia dá uma cara mais profissional às fraudes",
				image: NewsOne,
				href: "https://www.uol.com.br/tilt/noticias/redacao/2024/02/21/uso-inteligencia-artificial-golpes-virtuais.htm?cmpid=copiaecola",
			},
			{
				title: "Dompa anuncia lançamento de aplicativo de gamificação que visa premiar usuários em jornadas de autoconhecimento",
				description:
					"A Dompa, primeira plataforma 360 no Brasil com objetivo de revolucionar o segmento de sorteios legais por meio de Inteligência Artificial, anuncia o lançamento do seu novo produto, o Vollu, aplicativo de gamificação por assinatura que atua como um guia personalizado para o autoconhecimento.",
				image: NewsTwo,
				href: "https://conteudo.polinize.com/dompa-anuncia-lancamento-de-aplicativo-de-gamificacao-que-visa-premiar-usuarios-em-jornadas-de-autoconhecimento/",
			},
			{
				title: "Oito dicas para organizar sorteios bem-sucedidos",
				description:
					"A prática de sorteios online popularizou-se nas redes sociais, não apenas como meio de promover produtos, mas também como uma estratégia eficaz para envolver os usuários. Nesse contexto, compreender as leis e adotar práticas transparentes, em conformidade com as normas vigentes, torna-se essencial para assegurar o sucesso de um sorteio.",
				image: NewsThree,
				href: "https://bnldata.com.br/oito-dicas-para-organizar-sorteios-bem-sucedidos/",
			},
		],
		[],
	);

	const mainNews = useMemo(() => news.slice(0)[0], [news]);
	const subNews = useMemo(() => news.splice(1, 2), [news]);

	const app = useOvermind("app");

	// Render for mobile
	if (app.state.isMobile) {
		return (
			<div className="px-[15px] pt-[10.667vw]" id="midia">
				<div className="">
					<div className="flex justify-between items-center">
						<div className="text-[6.4vw] font-titillium text-azul font-semibold uppercase">
							Dompa na mídia
						</div>

						<div className="w-[16vw] bg-azul h-[.1rem]" />

						<div className="flex flex-col items-end gap-[3.2vw]">
							{logos &&
								logos.map((logo) => (
									<img
										style={{
											width: logo.width,
											height: logo.height,
										}}
										key={logo.label}
										src={logo.image}
										alt="logo"
									/>
								))}
						</div>
					</div>

					<div className=" pt-[8.533vw]">
						<div className="font-roboto text-branco">
							<div className="">
								<div className="relative w-full h-[61.6vw] px-[4vw] pb-[4vw] flex flex-col justify-end">
									<div className="absolute inset-0 bg-gradient-to-t from-azul from-10% to-100% z-10 rounded-xl" />
									<img
										className="absolute inset-0 object-cover object-center w-full h-full rounded-xl"
										src={mainNews.image}
									/>
									<div className="text-[4.267vw] relative z-10 max-w-[80%] leading-none">
										{mainNews.title}
									</div>
									<div
										className="text-[2.133vw] relative z-10 pt-[3.2vw]"
										style={{ paddingRight: 0 }}
									>
										{mainNews.description}...
									</div>
								</div>
								<div className="relative z-10 max-w-max ml-auto pt-[3.467vw] pb-[1vw] mb-[3.467vw] border-b-2 border-roxo-3">
									<Button
										href={mainNews.href}
										target={"_blank"}
										template={"link-underline-purple"}
										size={"medium"}
										label={"Leia a Matéria"}
										IconLeft={IconNewWindow}
										className="text-[2.667vw] text-roxo-3"
									/>
								</div>
							</div>
						</div>

						<div className="space-y-[4.267vw]">
							{subNews &&
								subNews.map((news) => (
									<div
										key={news.title}
										className="flex justify-center items-center space-x-[4.8vw]"
									>
										<div className="">
											<div className="w-[29.229vw] h-[21.923vw] relative">
												<img
													className="absolute inset-0 object-cover object-top w-full h-full rounded-xl"
													src={news.image}
												/>

												<div className="absolute inset-0 bg-gradient-to-t from-azul from-10% to-50% z-10 rounded-xl" />
											</div>
										</div>
										<div className="space-y-[2.667vw] leading-none">
											<div className="font-roboto text-[2.667vw] text-preto">
												{news.title}
											</div>
											<div className="font-roboto text-[2.133vw] text-preto line-clamp-2">
												{news.description}
											</div>
											<div className="relative z-10 max-w-max ml-auto pb-[1vw] border-b-2 border-roxo-3">
												<Button
													template={
														"link-underline-purple"
													}
													href={news.href}
													target={"_blank"}
													size={"medium"}
													label={"Leia a Matéria"}
													IconLeft={IconNewWindow}
													iconHeight={14}
													iconWidth={16}
													className="text-[2.667vw] text-roxo-3"
												/>
											</div>
										</div>
									</div>
								))}
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div
			className="d-flex flex-row d-flex justify-content-center position-relative "
			id="midia"
		>
			<div className="dompa-media d-flex justify-content-center flex-column debug1 block-content">
				<div className="d-flex mb-row-column ">
					<div className="main-title flex-grow-0 mb-text-center">
						Dompa na mídia
					</div>
					<div className="mid-line-container flex-grow-1 flex-fill d-flex justify-content-center align-items-center p-4">
						<div className="mid-line debug1" />
					</div>
					<div className="logos d-flex flex-row flex-grow-0 justify-content-center align-items-center gap-4">
						{logos &&
							logos.map((logo) => (
								<img
									className="max-w-[120px]"
									key={logo.label}
									src={logo.image}
									height={44}
									alt="logo"
								/>
							))}
					</div>
				</div>

				<div className="news-container d-flex mb-row-column debug3 pt-4">
					<div className="main-news flex-grow-0 debug2 ">
						<div className="d-flex flex-column ">
							<div className="main-news-container d-flex flex-column justify-content-end position-relative debug4">
								<div className="title text-28 fw-medium flex-grow-0 z-2 debug1">
									{mainNews.title}
								</div>
								<div
									className="description text-18 flex-grow-0 pt-3 z-2 debug2"
									style={{ paddingRight: 0 }}
								>
									{mainNews.description}
								</div>
								<div
									className="image boxed position-absolute z-n1 debug3"
									style={{
										backgroundImage: `url(${mainNews.image})`,
									}}
								/>
								<div className="position-absolute boxed main-gradient z-1" />
							</div>
							<div className="actions debug2 py-1 d-flex justify-content-end">
								<Button
									href={mainNews.href}
									target={"_blank"}
									template={"link-underline-purple"}
									size={"medium"}
									label={"Leia a Matéria"}
									IconLeft={IconNewWindow}
									iconHeight={14}
									iconWidth={16}
								/>
							</div>
						</div>
					</div>

					<div className="sub-news flex-grow-1 flex-fill debug2 d-flex flex-column">
						{subNews &&
							subNews.map((news) => (
								<div
									key={news.title}
									className="d-flex flex-row sub-news-container gap-4 debug4"
								>
									<div className="d-flex flex-column ">
										<div className="image-container d-flex flex-column justify-content-end position-relative debug4 position-relative ">
											<div
												className="image boxed position-absolute z-n1 debug3"
												style={{
													backgroundImage: `url(${news.image})`,
												}}
											/>
											<div className="position-absolute boxed sub-gradient z-1" />
										</div>
									</div>
									<div className="d-flex flex-column">
										<div className="title fw-medium flex-grow-0 z-2 debug1 text-18">
											{news.title}
										</div>
										<div
											className="description text-16 flex-grow-1 pt-3 z-2 debug2"
											style={{ paddingRight: 0 }}
										>
											{news.description}
										</div>
										<div className="actions debug2 py-1 d-flex justify-content-end pt-2">
											<Button
												template={
													"link-underline-purple"
												}
												href={news.href}
												target={"_blank"}
												size={"medium"}
												label={"Leia a Matéria"}
												IconLeft={IconNewWindow}
												iconHeight={14}
												iconWidth={16}
											/>
										</div>
									</div>
								</div>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default DompaMedia;
