import React, { useEffect, useState, useCallback, useRef } from "react";
import { Label, Input } from "reactstrap";
import { useOvermind } from "../../../../overmind";
import "./text.css";
import Name from "../../../../assets/images/static/talk-to-us/Name.svg";
import Email from "../../../../assets/images/static/talk-to-us/Mail.svg";
import Company from "../../../../assets/images/static/talk-to-us/Company.svg";
import Segment from "../../../../assets/images/static/talk-to-us/Segment.svg";
import Objective from "../../../../assets/images/static/talk-to-us/Objective.svg";
import Tools from "../../../../assets/images/static/talk-to-us/Tools.svg";

const InputText = ({
	form,
	field,
	required,
	enabled,
	name,
	id,
	placeHolder,
	feedback = "Campo inválido",
	className = "",
	...props
}) => {
	const handleChange = async (e) => {
		const value = e.target.value;
		await form.setFieldValue(name, value);

		if (props.onChange) {
			props.onChange({ value, form, field });
		}
	};

	const svgMap = {
		"form-input-name": Name,
		"form-input-email": Email,
		"form-input-companyName": Company,
		"form-input-segment": Segment,
		"form-input-objective": Objective,
		"form-input-usedTools": Tools,
	};

	const iconSrc = svgMap[id];

	const app = useOvermind("app");

	// Render for mobile
	if (app.state.isMobile) {
		return (
			<div className="relative w-[42.133vw] leading-none">
				<Label for={id}>
					<p className="text-azul font-roboto text-[3.2vw] pt-[2.667vw]">
						{field.label}
					</p>
				</Label>
				<div className="relative">
					{iconSrc && (
						<img
							src={iconSrc}
							alt="icon"
							className="absolute left-3 top-1/2 transform -translate-y-1/2 w-[3.2vw] h-[2.4vw]"
						/>
					)}
					<Input
						className="pl-[8vw] bg-transparent text-azul font-roboto w-[42.133vw] shadow-xl h-[5.867vw] form-control-custom "
						placeholder={field.label}
						name={name}
						id={id}
						value={form?.values ? form?.values[name] : null}
						onChange={handleChange}
						onBlur={form.handleBlur}
						onFocus={props.onFocus}
						disabled={!enabled}
					/>
				</div>
			</div>
		);
	}

	return (
		<>
			<Input
				className={className}
				placeholder={placeHolder}
				name={name}
				id={id}
				value={form?.values ? form?.values[name] : null}
				onChange={handleChange}
				onBlur={form.handleBlur}
				onFocus={props.onFocus}
				disabled={!enabled}
			/>
			<Label for={id}>{field.label}</Label>
		</>
	);
};

export default InputText;
