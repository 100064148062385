import React, { useState, useMemo, useEffect, useCallback } from "react";

import { ReactComponent as CirclesImage1 } from "../../../../assets/images/circles.svg";
import { ReactComponent as CirclesImage2 } from "../../../../assets/images/circles-2.svg";
import "./our-team.scss";
import Circles from "../Circles";

import ImageCEO from "../../../../assets/images/static/our-team/ceo.jpg";
import ImageCOO from "../../../../assets/images/static/our-team/coo.jpg";
import ImageCMO from "../../../../assets/images/static/our-team/cmo.jpg";
import ImageUIUX from "../../../../assets/images/static/our-team/ui-ux.jpg";
import ImageCopywriting from "../../../../assets/images/static/our-team/copywriting.jpg";
import ImageSocialMedia from "../../../../assets/images/static/our-team/social-media.jpg";

import ImageCFO from "../../../../assets/images/static/our-team/cfo.jpg";
import ImageComercial from "../../../../assets/images/static/our-team/comercial.jpg";
import ImageCTO from "../../../../assets/images/static/our-team/cto.jpg";
import ImageDevBack from "../../../../assets/images/static/our-team/dev-back.jpg";
import ImageDevFront from "../../../../assets/images/static/our-team/dev-front.jpg";
import ImageOperacao from "../../../../assets/images/static/our-team/operacao.jpg";
import ImageRH from "../../../../assets/images/static/our-team/rh.jpg";

import nl2br from "react-nl2br";
import useEmblaCarousel from "embla-carousel-react";
import { useOvermind } from "../../../../overmind";

const Card = ({
	Image,
	title,
	description,
	command,
	image,
	descriptionPaddingRight = "0rem",
	actions,
}) => {
	const app = useOvermind("app");

	if (app.state.isMobile) {
		return (
			<div className="flex justify-start items-end relative min-w-[50.133vw] h-[71.2vw] mx-[6.4vw] text-branco leading-none">
				<img
					className="absolute inset-0 object-cover w-full h-full rounded-2xl"
					src={Image}
					alt="equipe"
				/>
				<div className="relative z-20 font-roboto pl-[3.2vw] pt-[3.2vw] pr-[4.8vw] pb-[3.2vw] max-w-[44.133vw] bg-[#25395A] rounded-2xl">
					<div className="">
						<div className="font-bold text-[4.8vw]">
							{nl2br(title)}
						</div>
						<div className="text-[2.133vw] pt-[2.933vw]">
							{nl2br(description)}
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="our-team-card d-flex flex-column position-relative w-100 debug5">
			<div
				className="card-image boxed position-absolute z-n1 debug3"
				style={{ backgroundImage: `url(${Image})` }}
			>
				<div className="boxed position-absolute card-image z-n1 debug3 d-flex flex-column justify-content-end">
					<div className="card-info-box d-flex flex-column">
						<div className="card-title text-18 flex-grow-0 debug2">
							{nl2br(title)}
						</div>
						<div
							className="card-description text-16 flex-grow-0 debug2"
							style={{ paddingRight: descriptionPaddingRight }}
						>
							{nl2br(description)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
const OurTeam = ({ actions }) => {
	const cards = useMemo(
		() => [
			{
				Image: ImageCEO,
				title: "CEO",
				description: `O time de operações assegura que todas as etapas das campanhas sejam executadas com excelência, garantindo qualidade e eficiência nos processos.`,
				descriptionPaddingRight: "1rem",
				command: (e) => {},
			},
			{
				Image: ImageCOO,
				title: "COO",
				description: `Assegura que todas as etapas das campanhas sejam executadas com excelência, garantindo qualidade e eficiência nos processos.`,
				descriptionPaddingRight: "1rem",
				command: (e) => {},
			},
			{
				Image: ImageCMO,
				title: "CMO & PRODUTO",
				description: `Lidera a estratégia de marketing, alinhando ações de comunicação aos objetivos de negócio. É responsável por fortalecer a marca, expandir seu alcance e impulsionar o crescimento da empresa.`,
				descriptionPaddingRight: "1rem",
				command: (e) => {},
			},
			{
				Image: ImageUIUX,
				title: "UX & UI",
				description: `Projeta interfaces digitais que oferecem experiências intuitivas, priorizando a funcionalidade e a estética.`,
				descriptionPaddingRight: "1rem",
				command: (e) => {},
			},
			{
				Image: ImageCopywriting,
				title: "COPYWRITER",
				description: `Responsável por traduzir a essência da marca em textos estratégicos, capazes de engajar o público e impulsionar conversões de maneira eficaz.`,
				descriptionPaddingRight: "1rem",
				command: (e) => {},
			},
			{
				Image: ImageSocialMedia,
				title: "SOCIAL MÍDIA",
				description: `Gerencia a presença digital da empresa utilizando criatividade para promover campanhas que fortalecem a identidade da marca e ampliam o alcance nas plataformas online.`,
				descriptionPaddingRight: "1rem",
				command: (e) => {},
			},
			{
				Image: ImageComercial,
				title: "COMERCIAL",
				description: `Prospecta clientes, negocia contratos e fecha negócios, o comercial é responsável por conectar as soluções da empresa às necessidades do mercado.`,
				descriptionPaddingRight: "1rem",
				command: (e) => {},
			},
			{
				Image: ImageCTO,
				title: "CTO",
				description: `Responsável por gerenciar inovações, supervisionar o desenvolvimento de produtos digitais, garantir a infraestrutura de TI e impulsionar estratégias tecnológicas.`,
				descriptionPaddingRight: "1rem",
				command: (e) => {},
			},
			{
				Image: ImageCFO,
				title: "CFO",
				description: `Coordena o planejamento financeiro, controla orçamentos, analisa riscos e busca estratégias para maximizar os resultados econômicos, garantindo a saúde financeira para a empresa.`,
				descriptionPaddingRight: "1rem",
				command: (e) => {},
			},
			{
				Image: ImageOperacao,
				title: "OPERAÇÃO",
				description: `Executa e garante o funcionamento eficiente dos processos da empresa. A área de operação conecta planejamento à prática, gerenciando recursos, equipes e entregas para que os produtos ou serviços sejam realizados com qualidade e dentro dos prazos.`,
				descriptionPaddingRight: "1rem",
				command: (e) => {},
			},
			{
				Image: ImageRH,
				title: "RH",
				description: `A área que cuida do ativo mais valioso de uma empresa: as pessoas. Responsável por recrutamento, desenvolvimento de talentos e gestão de clima organizacional.`,
				descriptionPaddingRight: "1rem",
				command: (e) => {},
			},
			{
				Image: ImageDevFront,
				title: "DESENVOLVEDOR",
				description: `Cria, testa, mantém sistemas, aplicativos e plataformas digitais. Essa área transforma ideias em soluções funcionais, trabalhando para garantir performance, segurança e inovação nos produtos e serviços tecnológicos.`,
				descriptionPaddingRight: "1rem",
				command: (e) => {},
			},
			{
				Image: ImageDevBack,
				title: "DESENVOLVEDOR",
				description: `Cria, testa, mantém sistemas, aplicativos e plataformas digitais. Essa área transforma ideias em soluções funcionais, trabalhando para garantir performance, segurança e inovação nos produtos e serviços tecnológicos.`,
				descriptionPaddingRight: "1rem",
				command: (e) => {},
			},
		],
		[],
	);

	const app = useOvermind("app");
	const [emblaRef] = useEmblaCarousel({ loop: true, align: "center" });

	if (app.state.isMobile) {
		return (
			<div className="pt-[13.333vw]">
				<div className="">
					<div className="text-[3.2vw] font-roboto text-[#20324E] text-center">
						Nosso sucesso é construído por uma equipe
						multidisciplinar que trabalha com dedicação e paixão! 
					</div>
					<div className="py-[6.4vw] text-center font-titillium font-bold text-[8.533vw]">
						Nossa Equipe
					</div>
					<div ref={emblaRef}>
						<div className="flex">
							{cards && cards.map((card) => <Card {...card} />)}
						</div>
					</div>
				</div>

				<div className="font-titillium font-semibold text-[6.4vw] pl-[15px] pt-[13.867vw] relative leading-none">
					Juntos, nossos times <br /> colaboram para oferecer <br />{" "}
					soluções completas! 💙
					<CirclesImage1 className="w-[34.133vw] h-[38.133vw] absolute bottom-0 top-0 right-[-15%] -rotate-90" />
				</div>
			</div>
		);
	}

	return (
		<div className="our-team debug1 d-flex flex-row justify-content-center position-relative">
			<div className="d-flex flex-column block-content position-relative">
				{/*<div className="position-absolute boxed bg-gradient-1 z-2"/>*/}
				{/*<div className="position-absolute boxed bg-gradient-2 z-2"/>*/}

				<div className="d-flex flex-column content z-2">
					<div className="flex-grow-0 debug2 pre-title">
						Nosso sucesso é construído por uma equipe
						<br />
						multidisciplinar que trabalha com dedicação e paixão! 
					</div>
					<div className="flex-grow-0 debug2 title font-tt">
						Nossa Equipe
					</div>
					<div className="cards flex-grow-1 flex-fill z-3 debug1 ">
						{cards && cards.map((card) => <Card {...card} />)}
					</div>
				</div>

				<Circles
					// className="z-3"
					Image={CirclesImage1}
					rotateX={0}
					rotateY={0}
					rotate={45 * 2}
					width={720}
					height={720}
					left={"10%"}
					top={"31%"}
				/>
				<div className="title-together content z-2 font-tt">
					Juntos, nossos times colaboram para
					<br />
					oferecer soluções completas!
					<span className="heart">💙</span>
				</div>
			</div>
		</div>
	);
};

export default OurTeam;
