import React, { useState, useMemo, useEffect, useCallback } from "react";

import IMG from "../../../assets/images/static/services/social-listening.jpg";
import SC from "../../../assets/images/static/services/sucess-cases/social-listening.png";

export const dataSocialListening = {
	id: "social-listening",
	mainTitle: `Social\nListening`,
	mainDescription: `Entenda Seu Público e Antecipe Tendências. Monitore redes sociais para capturar opiniões, identificar tendências e fortalecer sua marca.`,
	title: `Conectando\nMarcas`,
	description: (
		<>
			O <strong>Social Listening</strong> monitora conversas nas redes
			sociais para capturar opiniões sobre sua marca, identificar
			tendências de mercado e analisar o comportamento do público.
			<br />
			Essa abordagem permite criar conexões mais assertivas e alinhar
			campanhas às expectativas dos consumidores.
		</>
	),
	image: IMG,
	functionsSubTitle: `Como Nossa Solução Pode Beneficiar Sua Marca?`,
	functions: [
		{
			title: `Monitoramento em Tempo Real`,
			description: `Capture menções, hashtags e interações relacionadas à sua marca.`,
		},
		{
			title: `Análise de Sentimento`,
			description: `Identifique o tom das conversas: positivo, negativo ou neutro.`,
		},
		{
			title: `Identificação de Tendências`,
			description: `Antecipe movimentos de mercado com base no comportamento do público.`,
		},
		{
			title: `Segmentação de Públicos`,
			description: `Categorize os consumidores com base em interesses e interações.`,
		},
		{
			title: `Insights Estratégicos`,
			description: `Relatórios detalhados para ajustar campanhas e melhorar a comunicação.`,
		},
	],
	benefitsSubTitle: `Por Que Escolher Nossa Solução de Social Listening?`,
	benefits: [
		{
			title: `Conexão\nAutêntica`,
			description: `Compreenda o que os consumidores\nrealmente pensam e esperam da sua marca`,
		},
		{
			title: `Decisões Orientadas\npor Dados`,
			description: `Utilize insights para alinhar estratégias\ne otimizar campanhas.`,
		},
		{
			title: `Antecipação\nde Crises`,
			description: `Identifique problemas antes que eles\nescalem, protegendo a reputação da marca`,
		},
		{
			title: `Engajamento\nPersonalizado`,
			description: `Construa interações mais significativas\ncom base no comportamento do público`,
		},
		{
			title: `Identificação de\nOportunidades`,
			description: `Descubra tendências para criar\nprodutos ou serviços inovadores`,
		},
	],
	applicationDescription: `Como Nossa Solução Está Transformando Marcas?`,
	applications: [
		{
			title: `Gestão de Reputação`,
			description: `Monitoramento de menções para proteger a imagem da marca.`,
		},
		{
			title: `Planejamento de Campanhas`,
			description: `Insights baseados em tendências ajudam a criar campanhas mais eficazes.`,
		},
		{
			title: `Lançamento de Produtos`,
			description: `Identifique demandas e preferências antes de introduzir novos produtos no mercado.`,
		},
		{
			title: `Acompanhamento de Concorrentes`,
			description: `Analise o desempenho de marcas concorrentes para ajustar sua estratégia`,
		},
	],
	successCase: {
		name: `engemon`,
		title: `Social L. em Ação`,
		image: SC,
		solution: `Monitoramento de redes sociais\ninternas e externas para capturar feedback e\nidentificar ajustes necessários.`,
		challenge: `Entender as\npercepções dos colaboradores\nsobre iniciativas internas de\nsustentabilidade.`,
		percent: 37,
		percentExplanation: `Engajamento interno`,
	},
};
