import React, { useState, useMemo, useEffect, useCallback } from "react";

import "./client-description.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as IconNewWindow } from "../../../../assets/images/icons/new-window.svg";
import { ReactComponent as Circle } from "../../../../assets/images/circles-3.svg";
// import WhoWeAreImage from "../../../../assets/images/static/who-we-are.jpg"
import { ReactComponent as IconScreen } from "../../../../assets/images/icons/screen.svg";
import nl2br from "react-nl2br";
import { ReactComponent as CirclesImage } from "../../../../assets/images/circles.svg";
import Circles from "../../../components/blocks/Circles";
import ResultsImage from "../../../../assets/images/backgrounds/resultados.jpg";
import services from "../../../../services";
import Button from "../../../components/layout/Button/Button";
import { useOvermind } from "../../../../overmind";

const ClientDescription = ({ data, ...props }) => {
	const navigate = useNavigate();

	const clients = useMemo(() => services.clients.list(), []);

	const app = useOvermind("app");

	const urlClient = window.location.pathname.substring(
		window.location.pathname.lastIndexOf("/") + 1,
	);

	if (app.state.isMobile) {
		return (
			<div className="px-[15px] pt-[13.333vw]">
				<div className="">
					<div className="mb-[6.4vw] py-[3.2vw] flex gap-[6.4vw] overflow-x-scroll text-nowrap w-full no-scrollbar bg-azul justify-start px-[8vw] items-center rounded-xl">
						{clients &&
							clients.map((client) => (
								<div
									onClick={() =>
										navigate(`/clientes/${client.id}`)
									}
									className={`text-[3.2vw] font-roboto text-branco ${
										client.id === urlClient
											? "font-semibold"
											: ""
									}`}
								>
									{client.name}
								</div>
							))}
					</div>

					<div className="leading-none">
						<div className="text-preto">
							<div className="font-titillium text-[10.667vw] font-bold">
								{nl2br(data.name)}
							</div>
							<div className="font-titillium text-[6.4vw] font-semibold pt-[6.4vw] pb-[3.2vw]">
								Desafios
							</div>
							<div className="font-roboto text-[3.2vw] max-w-[75%]">
								{nl2br(data.solution)}
							</div>
						</div>

						<div className="w-full min-h-[62.667vw] py-[8.533vw] rounded-2xl relative my-[8.533vw] shadow-xl">
							<img
								className="absolute inset-0 object-cover object-center w-full h-full rounded-xl z-10"
								src={data.image}
								alt="img"
							/>
						</div>
					</div>

					<div className="leading-none">
						<div className="">
							<div className="font-titillium font-semibold text-[6.4vw]">
								Soluções
							</div>
							<div className="font-roboto text-[3.2vw] pt-[3.2vw] pb-[8.533vw] max-w-[75%]">
								{nl2br(data.solution)}
							</div>
						</div>

						<div className="space-y-[6.4vw]">
							{data.solutionItems &&
								data.solutionItems.map((solution, index) => (
									<div className="flex items-center justify-start space-x-[4.267vw]">
										<div className="font-roboto font-semibold text-[5.333vw] border-azul-2 text-azul-2 border-3 rounded-full min-w-[8vw] min-h-[8vw] flex justify-center items-center">
											{index + 1}
										</div>
										<div className="font-roboto font-semibold text-[4.267vw]">
											{solution.description}
										</div>
									</div>
								))}
						</div>
					</div>

					<div className="pt-[13.333vw] pb-[6.667vw]">
						<div className="relative w-full min-h-[31.2vw] flex justify-center items-center rounded-xl shadow-md">
							<img
								className="absolute inset-0 object-cover object-top w-full h-full rounded-xl z-10"
								src={ResultsImage}
								alt="img"
							/>

							<div className="absolute inset-0 bg-gradient-to-t from-[#2E4770] from-20% to-100% z-10 opacity-20 rounded-xl" />
							<div className="absolute inset-0 bg-gradient-to-b from-[#2E4770] from-20% to-100% z-10 opacity-20 rounded-xl" />

							<div className="font-titillium text-[9.6vw] font-bold text-branco relative z-10">
								Resultados
							</div>
						</div>
						<div className="pt-[6.4vw] pb-[8.533vw] text-center leading-none font-roboto text-[3.2vw]">
							{data.results}
						</div>
						<div className="">
							<Button
								template="light-blue"
								href={data.website}
								target={"_blank"}
								size={"medium"}
								label={"Acesse o site"}
								iconHeight={14}
								iconWidth={16}
								className="bg-azul-3 font-normal text-branco text-[3.2vw] rounded-md w-[42.133vw] h-[10.4vw] mx-auto shadow-md"
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div
			className="d-flex flex-row d-flex flex-row justify-content-center position-relative z-5 pt-5"
			style={{ marginTop: "-4rem" }}
		>
			<div className="client-description d-flex flex-column debug1 block-content ">
				<div className="client-list d-flex flex-row justify-content-center mb-justify-unset align-items-center">
					{clients &&
						clients.map((client) => (
							<div
								onClick={() =>
									navigate(`/clientes/${client.id}`)
								}
								className="item"
							>
								{client.name}
							</div>
						))}
				</div>

				<div className="client-description-container d-flex flex-row ">
					<div className="flex-grow-1 flex-fill d-flex flex-column debug3 ">
						<div className="title debug2 text-128 font-tt">
							{nl2br(data.name)}
						</div>
						<div className="title-challenge debug2 text-128 font-tt">
							Desafios
						</div>
						<div className="description debug2 text-28 ">
							{nl2br(data.solution)}
						</div>
					</div>

					<div className="image-container flex-grow-0 d-flex flex-column debug4 debug3 position-relative">
						<div
							className="image boxed position-absolute z-2 debug3"
							style={{ backgroundImage: `url(${data.image})` }}
						/>
						{/*<div className="position-absolute boxed sub-gradient z-1"/>*/}
					</div>
				</div>

				<div className="client-solution-container d-flex flex-row ">
					<div className="flex-grow-1 flex-fill d-flex flex-column debug3 ">
						<div className="title-solution debug2 text-128 font-tt">
							Soluções
						</div>
						<div className="description debug2 text-28 ">
							{nl2br(data.solution)}
						</div>
					</div>

					<div className="solution-items flex-grow-0 d-flex flex-column debug4 debug3 position-relative ">
						{data.solutionItems &&
							data.solutionItems.map((solution, index) => (
								<div className="item d-flex flex-row align-items-center">
									<div className="bullet">{index + 1}</div>
									<div className="item-description">
										{solution.description}
									</div>
								</div>
							))}
					</div>
				</div>

				<div className="results-container d-flex flex-column ">
					<div className="results-image-container debug3 position-relative z-n1">
						<div
							className="results-image position-absolute boxed"
							style={{ backgroundImage: `url(${ResultsImage})` }}
						/>
						<div className="position-absolute boxed z-1 d-flex justify-content-center align-items-center">
							<div className="results-label font-tt">
								Resultados
							</div>
						</div>
					</div>
					<div className="results-description  debug3">
						{data.results}
					</div>
					<div className="results-actions  debug3 d-flex justify-content-center align-items-center">
						<Button
							template="light-blue"
							href={data.website}
							target={"_blank"}
							size={"medium"}
							label={"Acesse o site"}
							// IconLeft={IconNewWindow}
							iconHeight={14}
							iconWidth={16}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ClientDescription;
