import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Circles } from "../../assets/images/circles-6.svg";
import { ReactComponent as Circles2 } from "../../assets/images/circles-2.svg";
import BackgroundImage from "../../assets/images/backgrounds/clients.jpg";
import { ReactComponent as Pointer } from "../../assets/images/icons/pointer.svg";
import Hero from "../components/blocks/Hero/Hero";
import TalkToUs from "../components/blocks/TalkToUs/TalkToUs";
import Button from "../components/layout/Button/Button";
import Page from "../components/layout/Page/Page";
import History from "../components/blocks/History/History";
import OurMissionVision from "../components/blocks/OurMissionVision/OurMissionVision";
import OurValues from "../components/blocks/OurValues/OurValues";
import OurTeam from "../components/blocks/OurTeam/OurTeam";
import services from "../../services";
import ClientDescription from "./blocks/ClientDescription/ClientDescription";
import { useOvermind } from "../../overmind";

const ClientsPage = ({ ...props }) => {
	const params = useParams();
	const clientId = useMemo(() => params.clientId, [params]);
	const data = useMemo(
		() => services.clients.get(clientId || "bello-futvolei"),
		[clientId],
	);
	const app = useOvermind("app");

	const navigate = useNavigate();

	if (!data) return null;

	return (
		<Page>
			<Hero
				preTitle={
					<div
						className={`${
							app.state.isMobile ? null : "text-28 fw-bold"
						} `}
					>
						CONHEÇA MAIS SOBRE NOSSOS CLIENTES
					</div>
				}
				title={
					<div
						className={`${
							app.state.isMobile
								? null
								: "text-128 fw-bold font-tt"
						}`}
					>
						Clientes
					</div>
				}
				description={
					<div
						className={` ${app.state.isMobile ? null : "text-28"}`}
					>
						Construímos parcerias de sucesso com empresas que
						confiam em nossa expertise para alcançar ótimos
						resultados. Confira quem já transformou os desafios em
						conquistas conosco.
					</div>
				}
				BackgroundImage={BackgroundImage}
				backgroundPositionY={app.state.isMobile ? "-4rem" : "-18.5rem"}
				backgroundPaddingBottom="8rem"
				paddingTop={"12rem"}
				circleTop={app.state.isMobile ? "0rem" : "22rem"}
				CirclesImage={Circles2}
				circleRotate={0}
				circleRotateX={0}
				circleRotateY={0}
				circleTranslateX={"-240px"}
				// circleTranslateX={"-100px"}
				circleTranslateY={"-16rem"}
				actions={
					app.state.isMobile ? (
						<div className={`flex gap-[4.5vw]`}>
							<Button
								label="Contato"
								size={"big"}
								onClick={() => navigate(`/#contato`)}
								className="bg-azul text-branco w-[25vw] h-[5.87vw] rounded-md"
							/>
							<Button
								label="Nossos serviços"
								onClick={() => navigate(`/#servicos`)}
								template={"link"}
								size={"big"}
								IconRight={Pointer}
								className="text-azul"
							/>
						</div>
					) : (
						<>
							<Button
								label="Contato"
								size={"big"}
								onClick={() => navigate(`/#contato`)}
								className="bg-azul text-branco w-[94px] h-[22px] rounded-md"
							/>
							<Button
								label="Nossos serviços"
								onClick={() => navigate(`/#servicos`)}
								template={"link"}
								size={"big"}
								IconRight={Pointer}
								className="text-azul"
							/>
						</>
					)
				}
			/>

			<ClientDescription data={data} />
			{/*<TalkToUs />*/}
		</Page>
	);
};

export default ClientsPage;
