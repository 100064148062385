import React from "react";
import { DotButton, useDotButton } from "./EmblaCarouselDotButton";
import useEmblaCarousel from "embla-carousel-react";
import { ReactComponent as IconNewWindow } from "../../../../assets/images/icons/new-window.svg";
import { useNavigate } from "react-router-dom";

const EmblaCarousel = (props) => {
	const { slides, options } = props;
	const [emblaRef, emblaApi] = useEmblaCarousel(options);

	const { selectedIndex, scrollSnaps, onDotButtonClick } =
		useDotButton(emblaApi);

	const navigate = useNavigate();

	return (
		<section className="embla">
			<div className="embla__viewport" ref={emblaRef}>
				<div className="embla__container">
					{/* Render slide content dynamically */}
					{slides.map((slide, index) => (
						<div
							className="min-w-[42.133vw] h-[51.733vw] relative text-branco pt-[5.333vw] pl-[3.733vw] pr-[6.667vw] mx-[20px]"
							onClick={() => navigate(`/servicos/${slide.id}`)}
						>
							<div className="relative z-10 font-titillium font-semibold text-[4.267vw]">
								{slide.title}
							</div>
							<div className="relative z-10 font-roboto text-[2.667vw] pt-[4.267vw] leading-none w-full">
								{slide.description}
							</div>
							<div
								className="absolute inset-0 bg-cover rounded-xl"
								style={{
									backgroundImage: `url(${slide.image})`,
								}}
							/>

							<div className="absolute inset-0 bg-preto opacity-50 rounded-xl" />

							<div className="absolute bottom-0 right-0 z-10 bg-branco w-[7.8vw] h-[6.8vw] flex justify-end items-end rounded-tl-full">
								<div className="bg-preto w-[4.8vw] h-[4.8vw] flex justify-center items-center rounded-full">
									<IconNewWindow
										width={"2.133vw"}
										height={"2.133vw"}
									/>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>

			{/* Dots for navigation */}
			<div className="embla__controls flex justify-center pt-[6.4vw]">
				<div className="embla__dots ">
					{scrollSnaps.map((_, index) => (
						<DotButton
							key={index}
							onClick={() => onDotButtonClick(index)}
							className={"embla__dot".concat(
								index === selectedIndex
									? " embla__dot--selected"
									: "",
							)}
						/>
					))}
				</div>
			</div>
		</section>
	);
};

export default EmblaCarousel;
