import React, {useState, useMemo, useEffect, useCallback} from "react"


import AI from "../../../assets/images/static/services/ai.jpg"
import SC_AI from "../../../assets/images/static/services/sucess-cases/ai.png"

export const dataAi =
	{
		id : "inteligencia-artificial",
		mainTitle : `Inteligência\nArtificial`,
		mainDescription : `Inteligência Artificial: Transformando Dados em Decisões\nEstratégicas. Automação inteligente e análises avançadas para\nresultados precisos.`,
		title : `O Futuro\nEstá Aqui`,
		description : <>A Dompa utiliza um <strong>algoritmo proprietário baseado em Machine Learning</strong> para criar soluções avançadas e automações inteligentes.<br/>Essa tecnologia é capaz de realizar análises profundas, identificando padrões e tendências que ajudam nossos parceiros a tomar decisões assertivas.</>,
		image : AI,
		functionsSubTitle : `Principais Funcionalidades da nossa IA`,
		functions : [
			{
				title : `Automação Inteligente`,
				description : `Reduza processos manuais com soluções automatizadas e eficientes.`,
			},
			{
				title : `Análise Preditiva`,
				description : `Antecipe tendências e tome decisões baseadas em dados futuros.`,
			},
			{
				title : `Segmentação Personalizada`,
				description : `Identifique padrões de comportamento e segmente públicos com alta precisão.`,
			},
			{
				title : `Recomendações Estratégicas`,
				description : `Sugestões automatizadas para melhorar a performance das campanhas.`,
			},
			{
				title : `Integração de Dados`,
				description : `Conexão fluida entre diferentes fontes para análises centralizadas`,
			},
		],
		benefitsSubTitle : `Por que escolher Nossa Tecnologia de IA?`,
		benefits : [
			{
				title : `Decisões Baseadas\nem Dados`,
				description : `Insights confiáveis para\nreduzir incertezas`,
			},
			{
				title : `Eficiência\nOperacional`,
				description : `Automação de tarefas complexas para\neconomizar tempo e recursos`,
			},
			{
				title : `Maior\nPrecisão`,
				description : `Resultados mais detalhados e\npersonalizações otimizadas`,
			},
			{
				title : `Inovação\nContínua`,
				description : `IA atualizada constantemente para\nacompanhar tendências do mercado`,
			},
			{
				title : `Escalabilidade`,
				description : `Capacidade de adaptar soluções\nconforme o crescimento das demandas\ndo cliente.`,
			},
		],
		applicationDescription:`Como Nossa IA Está Fazendo a Diferença?`,
		applications : [
			{
				title : `Campanhas Promocionais`,
				description : `Segmentação de participantes e validação em tempo real`,
			},
			{
				title : `Análise de Mercado`,
				description : `Identificação de oportunidades com base em tendências de dados`,
			},
			{
				title : `Recomendações Personalizadas`,
				description : `Sugestões de ofertas baseadas no comportamento do cliente`,
			},
			{
				title : `Social\nListening`,
				description : `Monitoramento e análise de menções em redes sociais para insights estratégicos`,
			},
		],
		successCase : {
			name : `BYD`,
			title : `IA em Ação`,
			image : SC_AI,
			solution : `Aplicação de IA para integrar dados\ndo chassi e notas fiscais, com análise\nautomatizada.`,
			challenge : `Gerenciar dados de\n87 concessionárias e validar compras\nem tempo real.`,
			percent : 32,
			percentExplanation : `Redução de Erros`,
		},
	}



