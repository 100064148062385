import React, { useState, useMemo, useEffect, useCallback } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
// import {ReactComponent as CirclesImage1} from "../../../../assets/images/circles.svg"
// import {ReactComponent as CirclesImage2} from "../../../../assets/images/circles-2.svg"
import LogoDompa from "../../../../assets/images/logos/dompa-corp.png";
import "./footer.scss";
import services from "../../../../services";
import Button from "../Button/Button";
import { useOvermind } from "../../../../overmind";

const Footer = ({ actions }) => {
	const navigate = useNavigate();

	const [menu, setMenu] = useState(null);
	useEffect(() => {
		const products = services.products.list();
		const cases = services.cases.list();
		const allServices = services.services.list();
		setMenu([
			{
				id: "products",
				path: (item) => navigate(`/produtos/${item.id}`),
				label: "Produtos",
				items: products,
			},
			{
				id: "cases",
				path: (item) => navigate(`/clientes/${item.id}`),
				label: "Cases",
				items: cases,
			},
			{
				id: "services",
				path: (item) => navigate(`/servicos/${item.id}`),
				label: "Serviços",
				items: allServices,
			},
		]);
	}, []);

	const app = useOvermind("app");

	if (app.state.isMobile) {
		return (
			<div
				style={{
					background:
						"linear-gradient(180deg, #313131 0%, #20324E 224.02%)",
				}}
				className="px-[14px] pb-[6.667vw] mt-[10.667vw] min-h-[55.2vw] flex flex-col justify-end"
			>
				<div className="flex justify-between">
					<div className="flex justify-center items-end gap-[2.133vw]">
						<img
							src={LogoDompa}
							className="w-[22.741vw] h-[6.941vw]"
							alt="logo"
						/>

						<div className="font-roboto text-[1.6vw] text-branco">
							Revolucionando o Mercado
							<br />
							com Soluções
							<br />
							inteligentes
						</div>
					</div>

					<div className="flex flex-col gap-[3.2vw]">
						<Button
							template="light-blue"
							size={"medium"}
							label={"Contato"}
							onClick={() => navigate(`/#contato`)}
							iconHeight={14}
							iconWidth={16}
							className="bg-azul-3 text-branco w-[35.733vw] h-[8vw] font-semibold text-[3.2vw] rounded-md"
						/>
						<Button
							template="light-blue"
							size={"medium"}
							onClick={() => navigate(`/#servicos`)}
							label={"Nossos Serviços"}
							iconHeight={14}
							iconWidth={16}
							className="bg-azul-3 text-branco w-[35.733vw] h-[8vw] font-semibold text-[3.2vw] rounded-md"
						/>
					</div>
				</div>

				<div className="text-[2.667vw] text-center font-inter font-medium italic text-branco border-t-branco border-t-[1px] pt-[3.2vw] mt-[12vw]">
					Dompa Entretenimento Ltda e 48.935.528/0001-89
					{/* <a
						className="link"
						href={
							"https://dompa-file-folder.s3.sa-east-1.amazonaws.com/static-uploads/site+dompa_politica+_de_privacidade.pdf"
						}
						rel="noreferrer"
						target={"_blank"}
					>
						Política de Privacidade
					</a> */}
				</div>
			</div>
		);
	}

	return (
		<div className="footer debug1 d-flex flex-row justify-content-center position-relative">
			<div className="d-flex flex-column block-content ">
				{/*<div className="position-absolute boxed bg-gradient-1 z-2"/>*/}
				{/*<div className="position-absolute boxed bg-gradient-2 z-2"/>*/}

				<div className="menu-container d-flex flex-row debug2 title">
					{menu &&
						menu.map((group) => (
							<div
								key={group.label}
								className="menu-box d-flex flex-column"
							>
								<div className="title text-20">
									{group.label}
								</div>
								{group?.items &&
									group.items.map((menu) => (
										<div
											key={menu.name || menu.title}
											className="item text-16"
											onClick={() => group.path(menu)}
										>
											{menu.name || menu.mainTitle}
										</div>
									))}
							</div>
						))}
				</div>

				{/*<div className="cards flex-grow-1 flex-fill d-flex flex-row flex-wrap z-3 debug1">*/}
				{/*	{cards && cards.map(card => <Card {...card} />)}*/}
				{/*</div>*/}

				<div className="dompa-container d-flex mb-row-column debug2">
					<div className="logo-container flex-grow-0">
						<img src={LogoDompa} height={64} />
					</div>
					<div className="slogan flex-grow-1 flex-fill text-16">
						Revolucionando o Mercado
						<br />
						com Soluções
						<br />
						inteligentes
					</div>
					<div className="actions flex-grow-0 align-items-center">
						<div className="d-flex flex-row gap-4">
							<Button
								template="light-blue"
								size={"medium"}
								label={"Contato"}
								onClick={() => navigate(`/#contato`)}
								// IconLeft={IconNewWindow}
								iconHeight={14}
								iconWidth={16}
							/>
							<Button
								template="light-blue"
								size={"medium"}
								onClick={() => navigate(`/#servicos`)}
								label={"Nossos Serviços"}
								// IconLeft={IconNewWindow}
								iconHeight={14}
								iconWidth={16}
							/>
						</div>
					</div>
				</div>

				<div className="line-box pt-5 d-flex flex-column">
					<div className="line"></div>
				</div>
				<div className="copyright pt-2 pb-5 text-center text-16 d-flex flex-column">
					<div>
						Dompa Entretenimento Ltda e 48.935.528/0001-89 -{" "}
						<a
							className="link"
							href={
								"https://dompa-file-folder.s3.sa-east-1.amazonaws.com/static-uploads/site+dompa_politica+_de_privacidade.pdf"
							}
							target={"_blank"}
						>
							Política de Privacidade
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
