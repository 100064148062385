import dompaApi from "../../helpers/apis/dompaApi";

export let AppCache = {};

export const resetAppCache = () => {
};

export const setGotoUrl = (url) => {
  if (!url) localStorage.removeItem("goto-url");
  else localStorage.setItem("goto-url", url);
};

export const getGotoUrl = () => {
  return localStorage.getItem("goto-url");
};

resetAppCache();

export const talkToUs = async (data) => {
	return dompaApi
		.post(`talkToUs`, data)
		.then(res => res.data)
}
