import React, { useState, useMemo, useEffect, useCallback } from "react";

import "./products.scss";
import { ReactComponent as IconNewWindow } from "../../../../assets/images/icons/new-window.svg";
import { ReactComponent as CirclesImage4 } from "../../../../assets/images/circles-4.svg";
import { ReactComponent as ArrowLeft } from "../../../../assets/images/icons/arrow-left.svg";
import { ReactComponent as Sphere } from "../../../../assets/images/icons/sphere.svg";
import { ReactComponent as IconUsers } from "../../../../assets/images/icons/users.svg";
import { ReactComponent as IconInfluencers } from "../../../../assets/images/icons/influencers.svg";
// import WhoWeAreImage from "../../../../assets/images/static/who-we-are.jpg"
import services from "../../../../services";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import nl2br from "react-nl2br";
import Circles from "../Circles";
import Button from "../../layout/Button/Button";
import { useOvermind } from "../../../../overmind";

const Products = ({ ...props }) => {
	const [productId, setProductId] = useState("fazla");

	const products = useMemo(() => services.products.list(), []);
	const selectedProduct = useMemo(
		() => products.find((c) => c.id === productId),
		[productId, products],
	);

	const navigate = useNavigate();

	const app = useOvermind("app");

	// Render for mobile
	if (app.state.isMobile) {
		return (
			<div
				className="px-[15px] pt-[10.667vw] text-preto leading-[3.2vw]"
				id="produtos"
			>
				<div className="">
					<div className="text-[6.4vw] font-titillium font-bold">
						Originais por Proprietários
					</div>
					<div className="font-roboto text-[2.667vw] py-[4.267vw]">
						Projetos autorais criados internamente e oferecidos como
						soluções exclusivas para o mercado.
					</div>

					<div className="flex items-center">
						<div className="">
							<Sphere className="w-[4.267vw] h-[4.267vw]" />
						</div>
						<div className="font-titillium font-semibold text-[6.4vw] pl-[2.933vw]">
							{selectedProduct.name}
						</div>
						<div className="relative z-10 ml-auto place-self-end pb-[1vw] border-b-2 border-roxo-3">
							<Button
								template={"link-underline-purple"}
								size={"medium"}
								label={"Saiba Mais"}
								onClick={() =>
									navigate(`/produtos/${selectedProduct.id}`)
								}
								IconLeft={IconNewWindow}
								iconHeight={14}
								iconWidth={16}
								className="text-[2.667vw] text-roxo-3"
							/>
						</div>
					</div>
					<div className="">
						<div className="flex min-h-[67.733vw]">
							<div className="">
								{/* <Circles
									Image={CirclesImage4}
									rotateX={0}
									rotateY={0}
									rotate={40 - 45}
									width={380}
									height={380}
									top={70}
									left={45}
								/> */}
								<div className="py-[4.267vw] pr-[6.4vw] flex justify-center items-center h-full">
									<img
										className="min-w-[36.533vw]"
										src={selectedProduct.image}
										alt="img"
									/>
								</div>
							</div>
							<div className=" place-self-center pt-[4.267vw]">
								<div className="text-[4.267vw] font-titillium font-semibold leading-[4vw]">
									{selectedProduct.title}
								</div>
								<div className="text-[2.667vw] font-roboto pt-[4.267vw]">
									{selectedProduct.description}
								</div>
							</div>
						</div>
						<div className="">
							<div className="flex justify-between">
								{products?.length &&
									products.map((product) => (
										<div
											key={product.id}
											onClick={() =>
												setProductId(product.id)
											}
											className={`flex gap-[1.867vw] ${
												productId === product.id
													? "active"
													: "inactive"
											}`}
										>
											{/* productId !== product.id && */}
											<div
												className={`font-roboto text-[2.667vw] ${
													productId === product.id &&
													"text-[4.267vw]"
												}`}
											>
												{product.name}
											</div>
											<div
												className={`${
													productId !== product.id
														? "rotate-90"
														: "-rotate-90"
												}`}
											>
												<ArrowLeft className="w-[13px] h-[13px]" />
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div
			className="d-flex flex-row d-flex flex-row justify-content-center position-relative mt-5"
			id="produtos"
		>
			<div className="products d-flex flex-column block-content position-relative">
				<div className="main-title">Originais por Proprietários</div>
				<div className="main-description text-28">
					Projetos autorais criados internamente e oferecidos como
					soluções exclusivas para o mercado.
				</div>

				<div className="product-container d-flex flex-row align-items-center gap-2">
					<div className="flex-grow-0 d-flex ">
						<Sphere width={32} height={32} />
					</div>
					<div className="flex-grow-1 product-title text-60 ps-2">
						{selectedProduct.name}
					</div>
					<div className="flex-grow-0">
						<Button
							template={"link-underline-purple"}
							size={"medium"}
							label={"Saiba Mais"}
							onClick={() =>
								navigate(`/produtos/${selectedProduct.id}`)
							}
							IconLeft={IconNewWindow}
							iconHeight={14}
							iconWidth={16}
						/>
					</div>
				</div>
				<div className="d-flex debug1 pt-5 mb-row-column mb-column-reverse">
					<div className="d-flex debug1 pt-5 mb-row-column">
						<div className="image-container flex-grow-0 position-relative">
							<Circles
								Image={CirclesImage4}
								rotateX={0}
								rotateY={0}
								rotate={40 - 45}
								width={380}
								height={380}
								top={70}
								left={45}
							/>
							<div className="position-absolute image-box debug3">
								<div
									className="w-100 h-100 image"
									style={{
										backgroundImage: `url(${selectedProduct.image})`,
									}}
								/>
							</div>
						</div>
						<div className="info-container flex-grow-1 flex-fill">
							<div className="product-title text-32">
								{selectedProduct.title}
							</div>
							<div className="product-description text-18">
								{selectedProduct.description}
							</div>
							<div className="counter-container d-flex flex-row debug1 ">
								<div className="counter-user-container debug2">
									<div className="counter-user d-flex flex-column gap-3">
										<div className="text-60 fw-bold font-tt">
											{selectedProduct.users}
										</div>
										<div className="d-flex flex-row align-items-center">
											<div className="pe-2">
												<IconUsers
													width={32}
													height={32}
												/>
											</div>
											<div className="text-18 p-2">
												Usuários
											</div>
										</div>
									</div>
								</div>

								<div className="counter-line"></div>

								<div className="counter-user-container">
									<div className="counter-user d-flex flex-column gap-3">
										<div className="text-60 fw-bold font-tt">
											{selectedProduct.influencers}
										</div>
										<div className="d-flex flex-row align-items-center">
											<div className="pe-2">
												<IconInfluencers
													width={32}
													height={32}
												/>
											</div>
											<div className="text-18 p-2">
												{selectedProduct.partnerType}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="menu-container flex-grow-0 debug2">
						<div className="menu d-flex mb-column-row justify-content-center text-18">
							{products?.length &&
								products.map((product) => (
									<div
										key={product.id}
										onClick={() => setProductId(product.id)}
										className={`debug3 d-flex flex-row text-18 mb-text-center  item ${
											productId === product.id
												? "active"
												: "inactive"
										}`}
									>
										{productId !== product.id && (
											<div className="menu-icon flex-grow-0 mb-hide">
												<ArrowLeft
													width={20}
													height={20}
												/>
											</div>
										)}
										<div className="menu-title flex-grow-1 flex-fill">
											{product.name}
										</div>
									</div>
								))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Products;
