import React, { useState, useMemo, useEffect, useCallback } from "react";

import "./who-we-are.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as IconNewWindow } from "../../../../assets/images/icons/new-window.svg";
import { ReactComponent as Circle } from "../../../../assets/images/circles-3.svg";
import WhoWeAreImage from "../../../../assets/images/static/who-we-are.jpg";
import WhoWeAreImageDesk from "../../../../assets/images/static/who-we-are.png";
import Button from "../../layout/Button/Button";
import { useOvermind } from "../../../../overmind";

const WhoWeAre = ({ ...props }) => {
	const navigate = useNavigate();

	const app = useOvermind("app");

	if (app.state.isMobile) {
		return (
			<div className="px-[15px]">
				<div className="bg-preto text-branco px-[6.4vw] py-[8.533vw] rounded-xl">
					<div className="">
						<div className="">
							<div className="text-[8.533vw] font-bold font-titillium ">
								Quem Somos?
							</div>
							<div className="text-[2.667vw] font-roboto pt-[6.4vw] pb-[10.133vw]">
								A Dompa nasceu em 2020, com o espírito inovador
								de uma startup determinada a transformar o
								mercado de campanhas promocionais no Brasil, um
								setor gigante e culturalmente forte...
							</div>
							<div className="flex items-center justify-center relative">
								<div className="w-[39.733vw] h-[54.133vw] relative">
									<img
										className="absolute inset-0 object-cover object-center w-full h-full rounded-md z-10"
										src={WhoWeAreImage}
										alt="Who We Are"
									/>
								</div>

								<Circle className="w-[34.133vw] absolute left-0 bottom-0" />
								<Circle className="w-[10.4vw] absolute left-10 top-5" />
								<Button
									template={"link-underline"}
									onClick={() => navigate("/quemsomos")}
									size={"medium"}
									label={"Saiba Mais"}
									IconLeft={IconNewWindow}
									iconHeight={14}
									iconWidth={16}
									className={
										"flex-row-reverse absolute right-0 bottom-0 text-[2.667vw] font-roboto font-normal border-b"
									}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="d-flex flex-row d-flex flex-row justify-content-center position-relative mt-5">
			<div className="who-we-are d-flex flex-row debug1 block-content mt-5">
				<div className="container d-flex flex-row">
					<div className="flex-grow-1 flex-fill d-flex flex-column debug3">
						<div className="title debug2 text-96">Quem Somos?</div>
						<div className="description debug2 text-28 ">
							A Dompa nasceu em 2020, com o espírito inovador de
							uma startup determinada a transformar o mercado de
							campanhas promocionais no Brasil, um setor gigante e
							culturalmente forte...
						</div>
						<div className="actions debug2 fw-medium">
							<Button
								template={"link-underline"}
								onClick={() => navigate("/quemsomos")}
								size={"medium"}
								label={"Saiba Mais"}
								IconLeft={IconNewWindow}
								iconHeight={14}
								iconWidth={16}
							/>
						</div>
					</div>
					<div className="image-container flex-grow-0 position-relative mb-hide">
						<div className="position-absolute boxed w-100 z-2 debug4 ">
							<div
								className="position-absolute image-circle"
								style={
									{
										// transform:`translateX(${circleTranslateX}) translateY(${circleTranslateY})`
									}
								}
							>
								<Circle width={"auto'"} height={"100%"} />
							</div>
							<div className="position-absolute image-box overflow-hidden">
								<div
									className="bg-no-repeat bg-cover bg-right w-[105%] h-full"
									style={{
										backgroundImage: `url(${WhoWeAreImage})`,
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WhoWeAre;
