import React, {useState, useMemo, useEffect, useCallback} from "react"


import UltraCRM from "../../../assets/images/static/services/ultra-crm.jpg"
import SC_UltraCRM from "../../../assets/images/static/services/sucess-cases/ultra-crm.png"

export const dataUltraCrm =
	{
		id : "ultra-crm",
		mainTitle : `Ultra CRM`,
		mainDescription : `Analisa dados e cria ações personalizadas. No Vollu, ajudou a segmentar usuários, aumentando engajamento e resultados em campanhas.`,
		title : `O Que é o\nUltraCRM?`,
		description : `O UltraCRM é a solução da Dompa que organiza e segmenta dados com precisão, permitindo que sua empresa entenda melhor seu público e ofereça experiências personalizadas. Seja para campanhas promocionais ou estratégias de fidelização, o UltraCRM é a ferramenta essencial para empresas que valorizam decisões baseadas em dados.`,
		image : UltraCRM,
		functionsSubTitle : `Principais Funcionalidades do CRM`,
		functions : [
			{
				title : `Segmentação Avançada`,
				description : `Identifique padrões de comportamento e divida seu público em grupos altamente relevantes.`,
			},
			{
				title : `Curadoria de Dados Automatizada`,
				description : `Centralize e organize dados dispersos, eliminando inconsistências e duplicidades.`,
			},
			{
				title : `Análise Preditiva`,
				description : `Antecipe as necessidades dos clientes com base em comportamentos e tendências.`,
			},
			{
				title : `Integração Completa`,
				description : `Conecte o UltraCRM a suas ferramentas de marketing, vendas e BI.`,
			},
			{
				title : `Painel Intuitivo`,
				description : `Visualize insights em tempo real com dashboards fáceis de usar.`,
			},
		],
		benefitsSubTitle : `Por que escolher o UltraCRM?`,
		benefits : [
			{
				title : `Decisões Baseadas\nem Dados`,
				description : `Reduza achismos e tome decisões\nestratégicas com base em informações\nprecisas`,
			},
			{
				title : `Engajamento\nRelevante`,
				description : `Entregue mensagens certas para o\npúblico certo no momento ideal`,
			},
			{
				title : `Aumento de ROI`,
				description : `Maximize o retorno sobre o investimento\ncom campanhas altamente segmentadas`,
			},
			{
				title : `Fidelização\nde Clientes`,
				description : `Crie experiências únicas que aumentam a\nretenção e fortalecem o relacionamento`,
			},
			{
				title : `Eficiência\nOperacional`,
				description : `Automatize processos e concentre-se\nno que realmente importa: resultados.`,
			},
		],
		applicationDescription:`Como o UltraCRM Pode Transformar Seu Negócio?`,
		applications : [
			{
				title : `Campanhas Promocionais`,
				description : `Identifique o público mais propenso a engajar e otimize a alocação de recursos.`,
			},
			{
				title : `Fidelização de Clientes`,
				description : `Utilize dados para oferecer benefícios personalizados e programas de recompensas.`,
			},
			{
				title : `Social Listening e Monitoramento`,
				description : `Combine segmentação com insights de tendências para antecipar movimentos do mercado`,
			},
			{
				title : `E-commerce`,
				description : `Aumente vendas com recomendações baseadas em comportamentos de compra`,
			},
		],
		successCase : {
			name : `Vollu`,
			title : `UltraCRM em Ação`,
			image : SC_UltraCRM,
			solution : `Implementação do UltraCRM para\nsegmentação avançada e personalização de\nmensagens.`,
			challenge : `Organizar dados\ndispersos e engajar usuários\ncom campanhas\npersonalizadas.`,
			percent : 37,
			percentExplanation : `Engajamento e Conversão`,
		},
	}

