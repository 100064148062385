import React, {useState, useMemo, useEffect, useCallback} from "react"


import IMG from "../../../assets/images/static/services/campanhas-promocionais.jpg"
import SC from "../../../assets/images/static/services/sucess-cases/campanhas-promocionais.png"

export const dataCampanhasPromocionais =
	{
		id : "campanhas-promocionais",
		mainTitle : `Campanha\nPromocional`,
		mainDescription : `Engajamento que Transforma. Sorteios, promoções e prêmios\nfeitos sob medida para o sucesso da sua marca.`,
		title : `Conquiste\ne Fidelize`,
		description : <>As campanhas promocionais da Dompa oferecem formatos criativos e legais para atrair clientes e fortalecer a marca.<br/>Seja por meio de <strong>títulos de capitalização, sorteios, promoções comerciais ou vale-brinde,</strong> nossas soluções são personalizadas para atender às suas metas.</>,
		image : IMG,
		functionsSubTitle : `Principais Modalidades da Sua Campanha`,
		functions : [
			{
				title : ``,
				description : ``,
			},
			{
				title : `Títulos de\nCapitalização`,
				description : `Ofereça um investimento atrativo que combina sorteio e retorno financeiro, incentivando a participação.`,
			},
			{
				title : `Sorteios`,
				description : `Crie campanhas emocionantes que oferecem prêmios exclusivos e de alto valor.`,
			},
			{
				title : `Promoções\nComerciais`,
				description : `Aumente as vendas oferecendo descontos, prêmios e incentivos por compras ou participação.`,
			},
			{
				title : `Vale-Brinde`,
				description : `Ofereça recompensas instantâneas que encantam os clientes.`,
			},
		],
		benefitsSubTitle : `Por que escolher Nossa Tecnologia de IA?`,
		benefits : [
			{
				title : `Engajamento\nGarantido`,
				description : `Modalidades criativas e atrativas que\ncapturam a atenção do público`,
			},
			{
				title : `Resultados\nMensuráveis`,
				description : `Monitoramento em tempo real para\navaliar o impacto das campanhas`,
			},
			{
				title : `Fidelização\nde Clientes`,
				description : `Aumente o vínculo emocional com sua\nmarca`,
			},
			{
				title : `Flexibilidade e\nPersonalização`,
				description : `Escolha o formato que mais se adapta\nao seu público e objetivos`,
			},
			{
				title : `Conformidade\nLegal`,
				description : `Garantia de campanhas seguras e\nalinhadas às regulamentações`,
			},
		],
		applicationDescription:`Como Nossas Modalidades\nTransformam Campanhas?`,
		applications : [
			{
				title : `Campanhas de Varejo`,
				description : `Sorteios e vale-brindes para aumentar o fluxo de clientes`,
			},
			{
				title : `Lançamentos de Produtos`,
				description : `Promoções comerciais para criar buzz e atrair consumidores.`,
			},
			{
				title : `Programas de Fidelidade`,
				description : `Títulos de capitalização como benefício adicional para clientes recorrentes.`,
			},
			{
				title : `Eventos Especiais`,
				description : `Sorteios para engajar participantes e oferecer experiências memoráveis.`,
			},
		],
		successCase : {
			name : `BYD`,
			title : `Promocional em Ação`,
			image : SC,
			solution : `Implementação de sorteios com\nvalidação em tempo real e prêmios\npersonalizados.`,
			challenge : `Criar um sorteio\nnacional em 87 concessionárias\ncom prêmios exclusivos.`,
			percent : 24,
			percentExplanation : `Engajamento nacional`,
		},
	}



