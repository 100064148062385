import React, { useState, useRef, useEffect } from "react";

import InputMask from "react-input-mask";
import { onlyNumbers } from "../../../../helpers/utils";
import { Label } from "reactstrap";
import { isCpfFormat } from "../../Validations";
import "./text.css";
import Document from "../../../../assets/images/static/talk-to-us/Document.svg";
import { useOvermind } from "../../../../overmind";

const InputCpfCnpj = ({
	form,
	field,
	required,
	enabled,
	name,
	id,
	placeHolder,
	feedback = "Campo inválido",
	className = "w-100",
	...props
}) => {
	// let inputRef = useRef()
	const [isCpf, setIsCpf] = useState(false);
	const value = form?.values ? form?.values[name] : null;

	useEffect(() => {
		setIsCpf(isCpfFormat(value));
	}, [value]);

	const handleChange = async (e) => {
		const value = e.target.value;
		await form.setFieldValue(name, onlyNumbers(value));

		if (props.onChange) {
			props.onChange({ value, field, form });
		}
	};

	const app = useOvermind("app");

	// Render for mobile
	if (app.state.isMobile) {
		return (
			<div className="relative w-[42.133vw] leading-none">
				<Label for={id}>
					<p className="text-azul font-roboto text-[3.2vw] pt-[2.667vw]">
						{field.label}
					</p>
				</Label>
				<div className="relative">
					<img
						src={Document}
						alt="icon"
						className="absolute left-3 top-1/2 transform -translate-y-1/2 w-[3.2vw] h-[2.4vw]"
					/>
					<InputMask
						// ref={inputRef}
						className="pl-[8vw] bg-transparent text-azul font-roboto w-[42.133vw]  shadow-xl h-[5.867vw] form-control-custom rounded"
						mask={
							isCpf
								? "999.999.999-999999999"
								: "99.999.999/9999-99"
						}
						maskChar={value ? "" : null}
						placeholder={field.label}
						name={name}
						id={id}
						disabled={!enabled}
						autoComplete="disabled"
						type="text"
						inputMode="decimal"
						onChange={handleChange}
						onFocus={props.onFocus}
						value={value}
					/>
				</div>
			</div>
		);
	}

	return (
		<>
			<InputMask
				// ref={inputRef}
				className={className}
				mask={isCpf ? "999.999.999-999999999" : "99.999.999/9999-99"}
				maskChar={value ? "" : null}
				placeholder={placeHolder}
				name={name}
				id={id}
				disabled={!enabled}
				autoComplete="disabled"
				type="text"
				inputMode="decimal"
				onChange={handleChange}
				onFocus={props.onFocus}
				value={value}
			/>
			<Label for={id}>{field.label}</Label>
		</>
	);
};

export default InputCpfCnpj;
