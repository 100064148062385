import React, { useState, useMemo, useEffect, useCallback } from "react";

import "./service-description.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as IconNewWindow } from "../../../../assets/images/icons/new-window.svg";
import { ReactComponent as Circle } from "../../../../assets/images/circles-3.svg";
// import WhoWeAreImage from "../../../../assets/images/static/who-we-are.jpg"
import { ReactComponent as IconScreen } from "../../../../assets/images/icons/screen.svg";
import nl2br from "react-nl2br";
import { ReactComponent as CirclesImage } from "../../../../assets/images/circles.svg";
import Circles from "../../../components/blocks/Circles";
import { useOvermind } from "../../../../overmind";

const ServiceDescription = ({ data, ...props }) => {
	const navigate = useNavigate();
	const app = useOvermind("app");

	if (app.state.isMobile) {
		return (
			<div className="mx-[15px] text-preto pt-[12.533vw] leading-none border-roxo-3 border-b-2 pb-[13.333vw]">
				<div className="">
					<div className="flex">
						<div className="flex-1">
							<div className="text-azul">
								<IconScreen className="w-[8.533vw]" />
							</div>
							<div className="text-[9.6vw] font-titillium font-bold pb-[6.4vw]">
								{nl2br(data.title)}
							</div>
							<div className="text-[3.2vw] font-roboto leading-[4vw] max-w-[42.133vw]">
								{nl2br(data.description)}
							</div>
						</div>

						{/* <div className="">
							<Circles
								className="z-0"
								Image={CirclesImage}
								width={606}
								height={606}
								top={app?.state?.isMobile ? -603 : -403}
								left={app?.state?.isMobile ? -300 : undefined}
							/>
						</div> */}

						<div className="max-w-[42.133vw] h-[61.333vw] relative flex-1 self-center mt-[15vw]">
							<img
								className="absolute inset-0 object-cover object-center w-full h-full rounded-xl z-10"
								width={"42.133vw"}
								height={"61.333vw"}
								alt="service"
								src={data.image}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="service-content d-flex flex-row d-flex flex-row justify-content-center position-relative z-5 pt-5">
			<div className="service-description d-flex flex-column debug1 block-content ">
				<div className="d-flex flex-row ">
					<div className="flex-grow-1 flex-fill d-flex flex-column debug3 ">
						<div className="debug2 icon-container">
							<IconScreen width={72} height={72} />
						</div>
						<div className="title debug2 text-128 font-tt">
							{nl2br(data.title)}
						</div>
						<div className="description debug2 text-28 ">
							{nl2br(data.description)}
						</div>
					</div>

					{/* <div className="flex-grow-0 d-flex flex-column debug4 debug3 position-relative">
						<Circles
							className="z-0"
							Image={CirclesImage}
							width={606}
							height={606}
							top={app?.state?.isMobile ? -603 : -403}
							left={app?.state?.isMobile ? -300 : undefined}
						/>
					</div> */}
					<div className="image-container flex-grow-0 d-flex flex-column debug4 debug3 position-relative mb-hide">
						<div
							className="image boxed position-absolute z-2 debug3 hide"
							style={{ backgroundImage: `url(${data.image})` }}
						/>
						{/*<div className="position-absolute boxed sub-gradient z-1"/>*/}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ServiceDescription;
