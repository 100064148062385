import React, { useState, useMemo, useEffect, useCallback } from "react";

import { ReactComponent as Circles } from "../../../../assets/images/circles.svg";
import "./hero.scss";
import { app } from "../../../../overmind/app";
import { useOvermind } from "../../../../overmind";
import Gradient from "../../../../assets/images/backgrounds/gradient.png";

const Hero = ({
	preTitle,
	title,
	description,
	BackgroundImage,
	backgroundPositionX = "center",
	backgroundPositionY = "top",
	backgroundPaddingBottom = 0,

	CirclesImage,
	circleRotate = 45,
	circleRotateX = 0,
	circleRotateY = 0,
	circleTranslateX = 0,
	circleTranslateY = 0,
	paddingTop = "8rem",
	circleTop = "25%",
	actions,
	isHome,
}) => {
	const app = useOvermind("app");

	if (app.state.isMobile) {
		return (
			<div className="relative overflow-hidden">
				<div className="absolute inset-0 bg-gradient-to-t from-branco from-20% to-100% z-10" />
				<div className="absolute inset-0 bg-gradient-to-b from-branco from-0% to-20% z-10" />
				<img className="absolute inset-0" src={BackgroundImage} />
				<div className="">
					<div className="pl-[15px] leading-none pt-[73px] space-y-[4.5vw] relative z-10">
						{preTitle && (
							<div className="text-azul text-[2.667vw] font-roboto font-semibold">
								{preTitle}
							</div>
						)}
						<div
							className={`font-titillium font-bold text-[6.4vw] ${
								isHome ? null : "text-[9.6vw]"
							}`}
						>
							{title}
						</div>
						<div className="font-roboto text-[2.67vw] max-w-[70%]">
							{description}
						</div>
						{actions && <div className="">{actions}</div>}
					</div>

					{CirclesImage && (
						<CirclesImage className="absolute right-0 bottom-0 w-[30%] h-[65%] z-20" />
					)}
				</div>
			</div>
		);
	}

	return (
		<div className="hero debug1 d-flex flex-row justify-content-center position-relative pb-4">
			<div
				className="d-flex flex-row block-content "
				style={{ paddingBottom: backgroundPaddingBottom }}
			>
				<div
					className="position-absolute boxed background-image z-n1"
					style={{
						backgroundImage: `url(${BackgroundImage})`,
						backgroundPositionX,
						backgroundPositionY,
					}}
				/>

				<div className="position-absolute boxed bg-gradient-1 z-n1" />
				<div className="position-absolute boxed bg-gradient-2 z-n1" />

				<div
					className="flex-grow-1 flex-fill d-flex flex-column z-3 debug-1 "
					style={{ paddingTop }}
				>
					{preTitle && (
						<div className="pre-title debut2">{preTitle}</div>
					)}
					<div className="title debug2">{title}</div>
					<div className="description debug2">{description}</div>
					{actions && <div className="actions debug2">{actions}</div>}
				</div>

				{CirclesImage && (
					<div
						className="position-absolute boxed w-100 z-2 debug4 overflow-x-hidden no-pointer-event"
						style={{ height: "200%" }}
					>
						<div
							className="position-absolute"
							style={{
								width: 606,
								height: 606,
								right: -606 / 2,
								top: circleTop,
								transform: `translateX(${circleTranslateX}) translateY(${circleTranslateY})`,
							}}
						>
							<CirclesImage
								width={"auto'"}
								height={"100%"}
								style={{
									transform: `rotateX(${circleRotateX}deg) rotateY(${circleRotateY}deg) rotate(${circleRotate}deg)`,
									transformOrigin: "center",
								}}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Hero;
