import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Circles } from "../../assets/images/circles-2.svg";
import BackgroundImage from "../../assets/images/backgrounds/who-we-are.jpg";
import { ReactComponent as Pointer } from "../../assets/images/icons/pointer.svg";
import Hero from "../components/blocks/Hero/Hero";
import TalkToUs from "../components/blocks/TalkToUs/TalkToUs";
import Button from "../components/layout/Button/Button";
import Page from "../components/layout/Page/Page";
import History from "../components/blocks/History/History";
import OurMissionVision from "../components/blocks/OurMissionVision/OurMissionVision";
import OurValues from "../components/blocks/OurValues/OurValues";
import OurTeam from "../components/blocks/OurTeam/OurTeam";
import { useOvermind } from "../../overmind";

const WhoWeArePage = ({ ...props }) => {
	const navigate = useNavigate();
	const app = useOvermind("app");

	return (
		<Page>
			<Hero
				preTitle={
					<div
						className={` ${
							app.state.isMobile
								? "text-azul text-[2.667vw] font-roboto font-semibold"
								: "text-28 fw-bold"
						}`}
					>
						CONHEÇA MAIS SOBRE A DOMPA
					</div>
				}
				title={
					<div
						className={`${
							app.state.isMobile
								? "text-[9.6vw] font-titillium font-bold"
								: "text-128 fw-bold font-tt"
						}`}
					>
						Quem somos?
					</div>
				}
				description={
					<div
						className={`${
							app.state.isMobile
								? "font-roboto text-[2.667vw]"
								: "text-28"
						}`}
					>
						Lideramos a inovação em campanhas promocionais com uma
						plataforma que utiliza inteligência artificial e
						tecnologia de ponta para criar experiências únicas e
						impulsionar marcas.
					</div>
				}
				BackgroundImage={BackgroundImage}
				backgroundPositionY="-8.5rem"
				backgroundPaddingBottom="8rem"
				paddingTop={"15rem"}
				circleTop={app.state.isMobile ? "4rem" : "33rem"}
				CirclesImage={Circles}
				circleRotate={0}
				circleRotateX={0}
				circleRotateY={0}
				circleTranslateX={"-160px"}
				actions={
					app.state.isMobile ? (
						<div className={`flex gap-[4.5vw]`}>
							<Button
								label="Contato"
								size={"big"}
								onClick={() => navigate(`/#contato`)}
								className="bg-azul text-branco w-[25vw] h-[5.87vw] rounded-md"
							/>
							<Button
								label="Nossos serviços"
								onClick={() => navigate(`/#produtos`)}
								template={"link"}
								size={"big"}
								IconRight={Pointer}
								className="text-azul"
							/>
						</div>
					) : (
						<>
							<Button
								label="Contato"
								size={"big"}
								onClick={() => navigate(`/#contato`)}
							/>
							<Button
								label="Nossos produtos"
								onClick={() => navigate(`/#produtos`)}
								template={"link"}
								size={"big"}
								IconRight={Pointer}
							/>
						</>
					)
				}
				// circleTranslateX={"-100px"}
				circleTranslateY={"-100px"}
			/>

			<History />
			<OurMissionVision />
			<OurValues />
			<OurTeam />

			{/*<TalkToUs />*/}
		</Page>
	);
};

export default WhoWeArePage;
