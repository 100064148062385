import React, { useState, useMemo, useEffect, useCallback } from "react";

// import {ReactComponent as CirclesImage1} from "../../../../assets/images/circles.svg"
// import {ReactComponent as CirclesImage2} from "../../../../assets/images/circles-2.svg"
import "./service-functions.scss";
import { ReactComponent as Func1 } from "../../../../assets/images/static/services/icons/func1.svg";
import { ReactComponent as Func2 } from "../../../../assets/images/static/services/icons/func2.svg";
import { ReactComponent as Func3 } from "../../../../assets/images/static/services/icons/func3.svg";
import { ReactComponent as Func4 } from "../../../../assets/images/static/services/icons/func4.svg";
import { ReactComponent as Func5 } from "../../../../assets/images/static/services/icons/func5.svg";

import nl2br from "react-nl2br";
import Circles from "../../../components/blocks/Circles";
import { ReactComponent as CirclesImage } from "../../../../assets/images/circles-5.svg";
import { useOvermind } from "../../../../overmind";
import useEmblaCarousel from "embla-carousel-react";

const IconFunctions = [Func1, Func2, Func3, Func4, Func5];

const Card = ({
	Icon,
	title,
	description,
	command,
	image,
	descriptionPaddingRight = "0rem",
	actions,
	isEven,
}) => {
	const app = useOvermind("app");

	if (!title && !description) return null;

	if (app.state.isMobile) {
		return (
			<div
				className={`font-roboto min-w-[42.133vw] h-[50.133vw] px-[3.2vw] mr-[6.4vw] flex flex-col justify-center space-y-[3.2vw] items-center py-[4.8vw] rounded-xl text-center leading-none ${
					isEven ? "bg-roxo-3" : "bg-roxo-2"
				}`}
			>
				{Icon && (
					<div className={`${isEven ? "text-branco" : "text-preto"}`}>
						<Icon width={"5.867vw"} height={"6.4vw"} />
					</div>
				)}
				<div
					className={`text-[5.333vw] font-semibold ${
						isEven ? "text-branco" : "text-preto"
					}`}
				>
					{nl2br(title)}
				</div>
				<div
					className={`text-[2.667vw] ${
						isEven ? "text-branco" : "text-preto"
					}`}
				>
					{nl2br(description)}
				</div>
			</div>
		);
	}

	return (
		<div className="service-functions-card d-flex flex-column position-relative debug5">
			{Icon && (
				<div className="card-icon flex-grow-0 debug1">
					<Icon width={44} height={45} />
				</div>
			)}
			<div className="card-title text-28 flex-grow-0 debug2">
				{nl2br(title)}
			</div>
			<div
				className="card-description text-18 flex-grow-0 debug2"
				style={{ paddingRight: descriptionPaddingRight }}
			>
				{nl2br(description)}
			</div>
		</div>
	);
};

const ServiceFunctions = ({ data, actions }) => {
	const [emblaRef] = useEmblaCarousel({
		loop: true,
		align: "center",
	});

	const cards = data.functions;

	const app = useOvermind("app");

	if (app.state.isMobile) {
		return (
			<div className="border-roxo-3 border-b-2 pb-[13.333vw] mx-[15px] text-center">
				<div className="">
					<div className="">
						<div className="" />
						<div className="text-[9.6vw] font-bold font-titillium pt-[6.4vw]">
							Funcionalidades
						</div>
						<div className="text-[4vw] text-azul-2 font-roboto pb-[8.533vw]">
							{data.functionsSubTitle}
						</div>
						<div ref={emblaRef}>
							<div className="flex">
								{cards &&
									cards.map((card, index) => (
										<Card
											key={index}
											{...card}
											Icon={IconFunctions[index]}
											isEven={index % 2 === 0}
										/>
									))}
							</div>
						</div>
						<div className="" />
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="service-functions debug1 d-flex flex-row justify-content-center position-relative">
			<div className="d-flex flex-column block-content ">
				{/*<div className="position-absolute boxed bg-gradient-1 z-2"/>*/}
				{/*<div className="position-absolute boxed bg-gradient-2 z-2"/>*/}

				<div className="d-flex flex-column content z-2">
					<div className="flex-grow-0 debug2 line-top" />
					<div className="flex-grow-0 debug2 title font-tt text-96">
						Funcionalidades
					</div>
					<div className="flex-grow-0 debug2 sub-title">
						{data.functionsSubTitle}
					</div>
					<div className="cards flex-grow-1 flex-fill z-3 debug4 position-relative">
						<Circles
							className="zx-1 opacity-25"
							Image={CirclesImage}
							width={840}
							height={840}
							left={840 / 2 - 120}
							top={50}
						/>

						{cards &&
							cards.map((card, index) => (
								<Card
									key={index}
									{...card}
									Icon={IconFunctions[index]}
								/>
							))}
					</div>
					<div className="flex-grow-0 debug2 line-bottom" />
				</div>
			</div>
		</div>
	);
};

export default ServiceFunctions;
