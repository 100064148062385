import { useOvermind } from "../../../../overmind";
import "./button.scss";
import React, { useState, useMemo, useEffect, useCallback } from "react";

const Button = ({
	label,
	onClick,
	href,
	target,
	IconLeft,
	IconRight,
	type = "button",
	template = "blue",
	size = "medium",
	iconWidth = 24,
	iconHeight = 20,
	className,
	svgColor,
	...props
}) => {
	const Tag = href ? "a" : "button";

	const app = useOvermind("app");

	if (app.state.isMobile) {
		return (
			<Tag
				type={type}
				href={href}
				target={target}
				onClick={onClick}
				className={`flex justify-center  items-center gap-[1.5vw] font-roboto font-semibold text-[2.67vw] ${className}`}
				data-template={template}
				data-size={size}
			>
				{IconLeft && (
					<div className={`${svgColor}`}>
						<IconLeft className="w-[2.935vw] h-[2.52vw]" />
					</div>
				)}
				{label && <div>{label}</div>}
				{IconRight && (
					<div className={`${svgColor}`}>
						<IconRight className="w-[2.935vw] h-[2.52vw]" />
					</div>
				)}
			</Tag>
		);
	}

	return (
		<Tag
			type={type}
			href={href}
			target={target}
			onClick={onClick}
			className="button"
			data-template={template}
			data-size={size}
		>
			{IconLeft && (
				<div className="icon pe-2">
					<IconLeft width={iconWidth} height={iconHeight} />
				</div>
			)}
			{label && <div>{label}</div>}
			{IconRight && (
				<div className="icon ps-2">
					<IconRight width={iconWidth} height={iconHeight} />
				</div>
			)}
		</Tag>
	);
};

export default Button;
